import React, { useState, useEffect } from "react";
import { db } from "../../../../firebase"; // Firebase configuration
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import EnrollmentBarChart from "./Charts/EnrollmentBarChart";
import EnrollmentLineGraph from "./Charts/EnrollmentLineGraph";
import MajorPercentageBarChart from "./Charts/MajorDistributionBarChart";
import AcademicYearChart from "./Charts/AcademicYearChart";
import GradeDistributionChart from "./Charts/GradeDistributionChart";
import InsightsComponent from "./InsightsComponent";

const CourseAnalyticsModal = ({ isOpen, onClose, classData }) => {
  const [analyticsData, setAnalyticsData] = useState(null);

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      if (isOpen && classData && classData.courseInfo_courseNumber) {
        try {
          const courseRef = db.collection("courses").doc(classData.courseInfo_courseNumber);
          const docSnapshot = await courseRef.get();

          if (docSnapshot.exists) {
            setAnalyticsData(docSnapshot.data());
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching Firestore data:", error);
        }
      }
    };

    fetchAnalyticsData();
  }, [isOpen, classData]);

  if (!isOpen || !analyticsData) return null;

  const chartComponents = [
    {
      title: "Enrollment + Forecasted Demand",
      component: <EnrollmentBarChart data={analyticsData?.enrollmentData} />,
    },
    {
      title: "Enrollment Trends Over Time",
      component: <EnrollmentLineGraph data={analyticsData?.enrollmentData} />,
    },
    {
      title: "Major Distribution",
      component: <MajorPercentageBarChart data={analyticsData?.majorEnrollmentData} />,
    },
    {
      title: "Academic Year Distribution",
      component: <AcademicYearChart data={analyticsData?.yearEnrollmentData} />,
    },
    {
      title: "Grade Distribution",
      component: <GradeDistributionChart data={analyticsData?.gradeData} />,
    },
  ];

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
      onClick={onClose}
    >
      <div
        className="rounded-md bg-white p-4 w-full max-w-5xl max-h-[95vh]"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header Section */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-gray-800">
            {analyticsData?.courseNumber} - {analyticsData?.courseName} Analytics
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-800 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {/* Summary Card Section */}
        <InsightsComponent analyticsData={analyticsData} />

        {/* Chart Section */}
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          className="w-full h-[420px] p-2 border-2 border-gray-200 rounded-md"
        >
          {chartComponents.map((chart, index) => (
            <SwiperSlide key={index}>
              <div className="flex flex-col items-start rounded-md">
                <h3 className="text-lg ml-2 font-semibold text-gray-700 mb-1 self-start">
                  {chart.title}
                </h3>
                <div className="w-full h-[350px]">{chart.component}</div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <style jsx>{`
          /* Adjust the position of navigation buttons */
          .swiper-button-next,
          .swiper-button-prev {
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            margin-top: 0;
            color: #4b5563; /* Tailwind's Gray-700 */
            transition: box-shadow 0.3s ease; /* Smooth transition for shadow */
          }

          .swiper-button-next {
            right: 35px; /* Adjust the distance from the right edge */
          }

          .swiper-button-prev {
            left: 35px; /* Adjust the distance from the left edge */
          }
        `}</style>


        {/* Trends Section */}
        <div className="grid grid-cols-3 gap-4 mt-3">
          {["fall", "spring", "summer"].map((key) => {
            const keyLower = key.toLowerCase();

            return (
              <div key={key} className="px-3 py-2 border-2 border-gray-200 rounded-md">
                <h3 className="text-lg font-semibold capitalize mb-2">
                  {key} Enrollment Trends
                </h3>
                <div className="space-y-3 text-md mb-2">
                  <div className="flex items-center">
                    <span
                      className={`${
                        analyticsData?.trends?.[key]?.historical >= 0
                          ? "bg-green-100 text-green-500"
                          : "bg-red-100 text-red-500"
                      } font-bold rounded-md text-sm w-[45px] py-0.5 text-center mr-2`}
                    >
                      {analyticsData?.trends?.[key]?.historical >= 0 ? "+" : ""}
                      {analyticsData?.trends?.[key]?.historical}%
                    </span>
                    <p className="text-gray-600 text-sm">
                      Historical Trend (
                      {keyLower === "fall"
                        ? "FA21 - FA23"
                        : keyLower === "spring"
                        ? "SP21 - SP24"
                        : "SU21 - SU24"}
                      )
                    </p>
                  </div>
                  <div className="flex items-center">
                    <span
                      className={`${
                        analyticsData?.trends?.[key]?.forecasted >= 0
                          ? "bg-green-100 text-green-500"
                          : "bg-red-100 text-red-500"
                      } font-bold rounded-md text-sm w-[45px] py-0.5 text-center mr-2`}
                    >
                      {analyticsData?.trends?.[key]?.forecasted >= 0 ? "+" : ""}
                      {analyticsData?.trends?.[key]?.forecasted}%
                    </span>
                    <p className="text-gray-600 text-sm">
                      Forecasted Trend (
                      {keyLower === "fall"
                        ? "FA24"
                        : keyLower === "spring"
                        ? "SP25"
                        : "SU25"}
                      )
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CourseAnalyticsModal;
