import React, { useState, useEffect, useCallback } from 'react';
import MultiSelect from '../../../ClassSearch/MultiSelect';
import { toast } from 'react-toastify';
import { FaPlus } from 'react-icons/fa';
import { useAuth } from '../../../../contexts/AuthContext';
import TemplateModal from './TemplateModal';
import { db, auth } from '../../../firebase'; // Make sure you import the Firebase authentication module if needed

// NEW: If you need to clear the filter from router state
import { useLocation, useNavigate } from 'react-router-dom';

const SearchComponent = ({
  onSearch,
  students,
  onFilter,
  onSelectAll,
  selectedStudents,
  filteredStudentsCount,
  fieldTypes,
  everythingSelected,
  advisors,

  // NEW: optional props for the "dashboard filter"
  dashboardFilterType,
  dashboardFilterValue,
}) => {
  const { fetchedMajors } = useAuth();

  // NEW: if you want to read from router directly, you can do so here.
  // But typically we rely on the parent (StudentsPage) passing down the filter.
  const location = useLocation();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [availableFilters, setAvailableFilters] = useState({
    gradYear: [],
    program: [],
    advisors: [],
    performance: [],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    gradYear: [],
    program: [],
    advisors: [],
    performance: [],
  });
  const [operatorError, setOperatorError] = useState('');

  // Custom field-based filter
  const [selectedField, setSelectedField] = useState('');
  const [selectedOperator, setSelectedOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  // For the multi-select filters
  const [selectedGradYears, setSelectedGradYears] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [selectedAdvisors, setSelectedAdvisors] = useState([]);
  const [selectedPerformance, setSelectedPerformance] = useState([]);

  // Utility to remove fields, rename known ones, and otherwise prettify.
  const transformFieldName = (field) => {
    // 1. Filter out unwanted fields
    if (['classesTaken', 'classesRegistered'].includes(field)) {
      return null; 
    }
  
    // 2. Rename specific fields
    const renameMap = {
      planned: 'Classes Planned',
      taken: 'Classes Taken',
      total: 'Total Classes',
      GPA: 'GPA'
    };
    if (renameMap[field]) {
      return renameMap[field];
    }
  
    // 3. Convert camelCase or lowercase to a nice label
    const spaced = field.replace(/([A-Z])/g, ' $1');
    const capitalized = spaced
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  
    return capitalized;
  };

  // 1. Create a mapping for original -> transformed names
  const fieldNameMap = Object.keys(fieldTypes).reduce((map, field) => {
    const transformed = transformFieldName(field);
    if (transformed) {
      map[transformed] = field; // Map transformed name to original name
    }
    return map;
  }, {});
  
  // 2. Generate transformed field names for display
  const studentFields = Object.keys(fieldNameMap);

  // Load from /filters.json
  const fetchFilters = useCallback(async () => {
    try {
      const response = await fetch('/filters.json');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      data['program'] = Object.keys(fetchedMajors);
      data['advisors'] = advisors;

      setAvailableFilters(data);
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  }, [fetchedMajors, advisors]);

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  // Whenever the user searches in the search box
  useEffect(() => {
    onSearch(searchTerm);
  }, [searchTerm, onSearch]);

  // Whenever selectedFilters changes, pass them up
  useEffect(() => {
    onFilter(selectedFilters);
  }, [selectedFilters, onFilter]);

  // Combine the customFilter info with selectedFilters
  useEffect(() => {
    onFilter({
      ...selectedFilters,
      customFilter: {
        field: selectedField,
        operator: selectedOperator,
        value: filterValue,
        error: operatorError,
      },
    });
  }, [
    selectedFilters,
    selectedField,
    selectedOperator,
    filterValue,
    operatorError,
    onFilter,
  ]);

  // Keep selectedFilters in sync with multi-select states
  useEffect(() => {
    setSelectedFilters({
      gradYear: selectedGradYears,
      program: selectedPrograms,
      advisors: selectedAdvisors,
      performance: selectedPerformance,
    });
  }, [selectedGradYears, selectedPrograms, selectedAdvisors, selectedPerformance]);

  // NEW: On mount or whenever the "dashboardFilterType" changes,
  //      auto-apply the filter from the Dashboard if present
  useEffect(() => {
    if (dashboardFilterType && dashboardFilterValue) {
      // Example: If "plan" -> "Students without a plan" => planned = 0
      if (dashboardFilterType === 'plan') {
        if (dashboardFilterValue === 'Students without a plan') {
          setSelectedField('planned');
          setSelectedOperator('equals');
          setFilterValue('0');
        } else if (dashboardFilterValue === 'Students with a plan') {
          setSelectedField('planned');
          setSelectedOperator('greater than');
          setFilterValue('0');
        }
      }
      // If "credits" => handle "No Credits", "0 - 3 Credits", etc.
      if (dashboardFilterType === 'credits') {
        if (dashboardFilterValue === 'No Credits') {
          setSelectedField('planned');
          setSelectedOperator('equals');
          setFilterValue('0');
        } else if (dashboardFilterValue === '0 - 3 Credits') {
          setSelectedField('planned');
          setSelectedOperator('less than');
          setFilterValue('4');
        }
        // Extend logic for 10 - 12, 13 - 15, etc. as needed
      }
    }
  }, [dashboardFilterType, dashboardFilterValue]);

  // This helper is used for picking possible values from numeric or string fields
  const getPossibleValuesForField = (field) => {
    const uniqueValues = new Set();
    students.forEach((student) => {
      if (student[field] !== undefined && student[field] !== null) {
        if (Array.isArray(student[field])) {
          student[field].forEach((value) => uniqueValues.add(value));
        } else {
          uniqueValues.add(student[field]);
        }
      }
    });
    return Array.from(uniqueValues);
  };

  // Called when user clicks "Select All Students"
  const handleSelectAllClick = () => {
    onSelectAll();
  };

  // Called when user clicks "Reset Filters"
  const handleResetFilters = () => {
    setSearchTerm('');
    setSelectedFilters({
      gradYear: [],
      program: [],
      advisors: [],
      performance: [],
    });
    setSelectedField('');
    setSelectedOperator('');
    setFilterValue('');
    setOperatorError('');

    // NEW: If you want to clear the filter from React Router location,
    //      so that reloading won't bring it back:
    navigate('/students', { replace: true, state: {} });
  };

  return (
    <div className="p-4 bg-gray-50 shadow-md border rounded-lg">
      <h5 className="text-lg text-center font-bold mb-4">Filters</h5>

      {/* Search Filter */}
      <div className="mb-2">
        <label
          htmlFor="searchQuery"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Search:
        </label>
        <input
          type="text"
          id="searchQuery"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
        />
      </div>

      {/* Filter by Field */}
      <div className="mb-2">
        <label htmlFor="customField" className="block text-sm font-medium mb-1">
          Filter by Field:
        </label>
        <div className="rounded-md">
          <MultiSelect
            suggestions={studentFields} // Display transformed field names
            value={selectedField ? [transformFieldName(selectedField)] : []} // Display transformed name for selected field
            onChange={(e) => {
              const transformedName = e.target.value[0] || '';
              const originalName = fieldNameMap[transformedName]; // Get original name from the map
              setSelectedField(originalName); // Pass original name to setSelectedField
              setSelectedOperator('');
              setFilterValue('');
              setOperatorError('');
            }}
            placeholder="Select a field"
            required={false}
            tagColor="gray"
            singleSelect={true}
          />
        </div>
      </div>

      {/* Operator Selection */}
      {selectedField && (
        <div className="mt-2">
          <label htmlFor="operator" className="block text-sm font-medium mb-1">
            Operator:
          </label>
          <div className="rounded-md">
            <MultiSelect
              suggestions={['equals', 'greater than', 'less than']}
              value={selectedOperator ? [selectedOperator] : []}
              onChange={(e) => {
                const operator = e.target.value[0] || '';
                setSelectedOperator(operator);
                // If "greater than"/"less than" with a non-numeric field => show error
                if (
                  (operator === 'greater than' || operator === 'less than') &&
                  fieldTypes[selectedField] !== 'number'
                ) {
                  setOperatorError('Cannot use this operator with selected field');
                } else {
                  setOperatorError('');
                }
              }}
              placeholder="Select an operator"
              required={false}
              tagColor="gray"
              singleSelect={true}
            />
          </div>
          {operatorError && (
            <p className="text-red-500 text-xs mt-1">{operatorError}</p>
          )}
        </div>
      )}

      {/* Value Input */}
      {selectedOperator && !operatorError && (
        <div className="mt-2">
          <label htmlFor="filterValue" className="block text-sm font-medium mb-1">
            Value:
          </label>
          {fieldTypes[selectedField] === 'number' ? (
            <input
              type="number"
              id="filterValue"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              className="form-input mt-1 block w-full mb-2 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          ) : (
            <div className="rounded-md">
              <MultiSelect
                suggestions={getPossibleValuesForField(selectedField)}
                value={filterValue ? [filterValue] : []}
                onChange={(e) => setFilterValue(e.target.value[0] || '')}
                placeholder="Select a value"
                required={false}
                tagColor="gray"
                singleSelect={true}
              />
            </div>
          )}
        </div>
      )}

      {/* Graduation Year Filter */}
      <div className="mb-2">
        <label htmlFor="gradYear" className="block text-sm font-medium mb-1">
          Graduation Year:
        </label>
        <div className="rounded-md">
          <MultiSelect
            suggestions={availableFilters.gradYear}
            value={selectedGradYears}
            onChange={(e) => setSelectedGradYears(e.target.value)}
            placeholder="Select graduation years"
            required={false}
            tagColor="red"
          />
        </div>
      </div>

      {/* Program Filter */}
      <div className="mb-2">
        <label htmlFor="program" className="block text-sm font-medium mb-1">
          Program:
        </label>
        <div className="rounded-md">
          <MultiSelect
            suggestions={availableFilters.program}
            value={selectedPrograms}
            onChange={(e) => setSelectedPrograms(e.target.value)}
            placeholder="Select programs"
            required={false}
            tagColor="green"
          />
        </div>
      </div>

      {/* Performance Filter */}
      <div className="mb-2">
        <label htmlFor="performance" className="block text-sm font-medium mb-1">
          Performance:
        </label>
        <div className="rounded-md">
          <MultiSelect
            suggestions={availableFilters.performance}
            value={selectedPerformance}
            onChange={(e) => setSelectedPerformance(e.target.value)}
            placeholder="Select performance levels"
            required={false}
            tagColor="orange"
          />
        </div>
      </div>

      {/* Advisor Filter */}
      <div className="mb-2">
        <label htmlFor="advisors" className="block text-sm font-medium mb-1">
          Advisor:
        </label>
        <div className="rounded-md">
          <MultiSelect
            suggestions={availableFilters.advisors}
            value={selectedAdvisors}
            onChange={(e) => setSelectedAdvisors(e.target.value)}
            placeholder="Select advisors"
            required={false}
            tagColor="purple"
          />
        </div>
      </div>

      {/*
        If you want to show a "Templates" button again, uncomment:
      
        <button
          onClick={toggleTemplateModalVisibility}
          className="w-full px-4 py-2 rounded-md flex items-center space-x-2 justify-center bg-blue-500 hover:bg-blue-700 text-white"
        >
          <FaPlus size={14} className="text-sm" />
          <span className="text-sm">Templates</span>
        </button>
      */}

      <button
        className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded mt-2"
        onClick={handleSelectAllClick}
      >
        {everythingSelected ? 'Unselect All Students' : 'Select All Students'}
      </button>

      <button
        className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded mt-2"
        onClick={handleResetFilters}
      >
        Reset Filters
      </button>

      <div className="flex justify-center mt-3">
        <span className="bg-gray-200 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          {filteredStudentsCount} Students Displayed
        </span>
      </div>

      {/*
        <TemplateModal
          isTemplateModalVisible={isTemplateModalVisible}
          setIsTemplateModalVisible={setIsTemplateModalVisible}
          selectedStudents={selectedStudents}
        />
      */}
    </div>
  );
};

export default SearchComponent;
