import React, { useEffect, useState } from "react";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import GPAGraph from "./GPAGraph";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";

// ==================== HELPER FUNCTIONS ==================== //

const calculateGpaTrend = (student) => {
  const history = student?.surveyAnswers?.GPAHistory;
  if (!Array.isArray(history) || history.length < 2) return 0;
  const earliest = history[0];
  const latest = history[history.length - 1];
  return latest - earliest;
};

const calculatePersistenceScore = (gpa, gpaTrend, engagementCount) => {
  const normalizedGpa = Math.min(Math.max(gpa / 4, 0), 1);
  const trendMax = 0.25;
  let normalizedTrend = gpaTrend / trendMax;
  normalizedTrend = Math.min(Math.max(normalizedTrend, 0), 1);
  const maxEngagement = 10;
  let normalizedEngagement = 1 - engagementCount / maxEngagement;
  normalizedEngagement = Math.min(Math.max(normalizedEngagement, 0), 1);

  return (0.9 * normalizedGpa + 0.1 * normalizedEngagement) * 5;
};

const getPersistenceCategory = (score) => {
  if (score >= 4) return "high";
  if (score >= 3) return "moderate";
  if (score >= 2) return "low";
  return "veryLow";
};

const getGpaDescription = (gpa) => {
  if (gpa >= 3.8) {
    return (
      <>
        An <span className="text-green-600 font-semibold">excellent</span> GPA greatly increases the probability of graduating successfully.
      </>
    );
  } else if (gpa >= 3.5) {
    return (
      <>
        A <span className="text-green-600 font-semibold">very high</span> GPA is a strong indicator of graduation success.
      </>
    );
  } else if (gpa >= 3.0) {
    return (
      <>
        A <span className="text-green-600 font-semibold">high</span> GPA suggests a good chance of graduating, with some room for improvement.
      </>
    );
  } else if (gpa >= 2.5) {
    return (
      <>
        A <span className="text-yellow-600 font-semibold">moderate</span> GPA might present some challenges for graduation but is workable.
      </>
    );
  } else {
    return (
      <>
        A <span className="text-red-600 font-semibold">low</span> GPA could significantly hinder graduation prospects.
      </>
    );
  }
};

// ==================== MAIN COMPONENT ==================== //

const StudentAnalytics = ({ currentStudent, applicableEngagements }) => {
  const [activeIndex, setActiveIndex] = useState(0); // Track active slide index

  // Fixed constant values since dropdowns are removed
  const timeFrame = "full";
  const resolution = "month";

  const monthMap = {
    January: 0, February: 1, March: 2, April: 3,
    May: 4, June: 5, July: 6, August: 7,
    September: 8, October: 9, November: 10, December: 11
  };

  const clampGpa = (value) => Math.min(Math.max(value, 0), 4);

  const generateGPAData = (timeFrame, resolution) => {
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];
    const now = new Date();
    const studentGpa = parseFloat(currentStudent?.surveyAnswers?.GPA) || 0;

    const schoolStart = currentStudent?.SchoolStart;
    let periodStart;

    if (timeFrame === "lastYear") {
      const tentativeStart = new Date(now.getFullYear() - 1, now.getMonth(), 1);
      // Adjust periodStart if student's start date is after tentativeStart
      if (
        schoolStart &&
        schoolStart.month &&
        schoolStart.year &&
        monthMap[schoolStart.month]
      ) {
        const studentStart = new Date(
          parseInt(schoolStart.year, 10),
          monthMap[schoolStart.month],
          1
        );
        periodStart = studentStart > tentativeStart ? studentStart : tentativeStart;
      } else {
        periodStart = tentativeStart;
      }
    } else if (
      schoolStart &&
      schoolStart.month &&
      schoolStart.year &&
      monthMap[schoolStart.month]
    ) {
      const startMonthIndex = monthMap[schoolStart.month];
      const startYear = parseInt(schoolStart.year, 10);
      periodStart = new Date(startYear, startMonthIndex, 1);
    } else {
      periodStart = new Date(now.getFullYear() - 1, now.getMonth(), 1);
    }

    const periodEnd = new Date(now.getFullYear(), now.getMonth(), 1);
    const gpaData = [];

    const step = resolution === "quarter" ? 3 : 1;
    const tempDate = new Date(periodStart);
    let totalSteps = 0;
    while (tempDate <= periodEnd) {
      totalSteps++;
      tempDate.setMonth(tempDate.getMonth() + step);
    }

    let startGpa = studentGpa;
    if (studentGpa <= 2.5) {
      const variation = Math.random() * 0.5 + 0.5;
      startGpa = clampGpa(studentGpa + variation);
    }

    tempDate.setTime(periodStart.getTime());
    let currentStep = 0;

    while (tempDate <= periodEnd) {
      const monthName = monthNames[tempDate.getMonth()];
      const year = tempDate.getFullYear();

      currentStep++;
      const fraction = totalSteps > 0 ? currentStep / totalSteps : 1;
      // Higher noise multiplier for earlier periods, decreasing over time
      const noiseMultiplier = 1 - fraction;

      let simulatedGpa;

      if (studentGpa >= 3.9) {
        simulatedGpa = clampGpa(
          studentGpa + (Math.random() * 0.1 - 0.05) * noiseMultiplier
        );
      } else if (studentGpa <= 2.5) {
        let interpolatedGpa = startGpa + (studentGpa - startGpa) * fraction;
        interpolatedGpa += (Math.random() * 0.1 - 0.05) * noiseMultiplier;
        simulatedGpa = clampGpa(interpolatedGpa);
      } else {
        simulatedGpa = clampGpa(
          studentGpa + (Math.random() * 0.2 - 0.1) * noiseMultiplier
        );
      }

      gpaData.push({
        period:
          resolution === "month"
            ? `${monthName} ${year}`
            : `Q${Math.floor(tempDate.getMonth() / 3) + 1} ${year}`,
        gpa: parseFloat(simulatedGpa.toFixed(2)),
      });

      tempDate.setMonth(tempDate.getMonth() + step);
    }

    return gpaData;
  };

  const gpaData = generateGPAData(timeFrame, resolution);
  const studentGpa = parseFloat(currentStudent?.surveyAnswers?.GPA) || 0;
  const gpaTrend = calculateGpaTrend(currentStudent);
  const engagementCount = Array.isArray(applicableEngagements)
    ? applicableEngagements.length
    : 0;
  const persistenceScore = calculatePersistenceScore(
    studentGpa,
    gpaTrend,
    engagementCount
  );
  const category = getPersistenceCategory(persistenceScore);

  let borderColorClass = "border-t-8 border-green-500";
  let categoryLabel = "High";

  switch (category) {
    case "moderate":
      borderColorClass = "border-t-8 border-yellow-500";
      categoryLabel = "Moderate";
      break;
    case "low":
      borderColorClass = "border-t-8 border-orange-500";
      categoryLabel = "Low";
      break;
    case "veryLow":
      borderColorClass = "border-t-8 border-red-500";
      categoryLabel = "Very Low";
      break;
    default:
      borderColorClass = "border-t-8 border-green-500";
      categoryLabel = "High";
  }

  useEffect(() => {
    if (!currentStudent?.uid) return;

    const persistenceDocRef = doc(db, "Persistence", currentStudent.uid);
    const userDocRef = doc(db, "users", currentStudent.uid);

    const createOrUpdatePersistence = async () => {
      const predictorData = {
        studentId: currentStudent.uid,
        firstName: currentStudent.firstName || "",
        lastName: currentStudent.lastName || "",
        prediction: category,
        gpaScore: studentGpa,
        gpaTrendScore: gpaTrend,
        engagementScore: engagementCount,
        overallPersistenceScore: persistenceScore,
        timestamp: new Date(),
      };

      try {
        const docSnap = await getDoc(persistenceDocRef);
        if (!docSnap.exists()) {
          await setDoc(persistenceDocRef, predictorData);
          await updateDoc(userDocRef, { persistenceId: currentStudent.uid });
        } else {
          await setDoc(persistenceDocRef, predictorData, { merge: true });
        }
      } catch (error) {
        console.error("Error updating persistence document:", error);
      }
    };

    createOrUpdatePersistence();
  }, [
    currentStudent?.uid,
    category,
    studentGpa,
    gpaTrend,
    engagementCount,
    persistenceScore,
  ]);

  return (
    <div className="w-full">
      <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          slidesPerView={1}
          spaceBetween={0}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          className="w-full max-h-[500px] min-h-[470px]"
        >
          <SwiperSlide>
            <div className="w-full flex flex-col justify-center items-start">
              {/* New Heading for GPA Progression */}
              <h2 className="text-gray-700 text-xl font-semibold mb-4">GPA Progression</h2>
              <div className="flex-grow flex justify-center items-start w-full">
                <GPAGraph gpaData={gpaData} resolution={resolution} />
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="w-full flex flex-col">
              {/* New Heading for Persistence Analytics */}
              <h2 className="text-gray-700 text-xl font-semibold mb-2">Persistence Analytics</h2>
              <div className="w-full px-16 py-3 h-full flex flex-col gap-4">
                <div className={`w-full bg-white rounded shadow-md p-4 ${borderColorClass}`}>
                  <h2 className="text-xl font-bold text-gray-800 mb-2">
                    {currentStudent?.firstName} {currentStudent?.lastName}
                  </h2>
                  <p className="text-sm text-gray-600 mb-4">
                    Persistence Prediction: <strong>{categoryLabel}</strong>
                    <span className="text-xs text-gray-500 ml-2">
                      (Score: {persistenceScore.toFixed(2)}/5)
                    </span>
                  </p>
                  <p className="text-md text-gray-700 mb-1">
                    Engagement Opportunities: <strong>{engagementCount}</strong>
                  </p>
                  <div className="flex-grow" />
                  <div className="flex justify-end">
                    <p className="text-md text-gray-700">
                      GPA: <strong>{studentGpa.toFixed(2)}</strong>
                    </p>
                  </div>
                </div>

                <div className="w-full bg-white rounded shadow-md p-4">
                  <h3 className="text-lg font-semibold mb-2">Persistence Factors</h3>
                  <ul className="list-disc list-inside text-sm space-y-1">
                    <li>
                      <strong>GPA:</strong> {getGpaDescription(studentGpa)}
                    </li>
                    <li>
                      <strong>GPA Trend:</strong>{" "}
                      {gpaTrend >= 0 ? (
                        <>
                          An <span className="text-green-600 font-semibold">improving</span> or stable GPA trend boosts graduation prospects.
                        </>
                      ) : (
                        <>
                          A <span className="text-red-600 font-semibold">declining</span> GPA trend is a warning sign for graduation challenges.
                        </>
                      )}
                    </li>
                    <li>
                      <strong>Engagement Opportunities:</strong>{" "}
                      {engagementCount < 3 ? (
                        <>
                          Fewer engagement issues suggest a <span className="text-green-600 font-semibold">higher</span> chance of graduation success.
                        </>
                      ) : (
                        <>
                          A <span className="text-red-600 font-semibold">higher</span> number of engagements may indicate challenges affecting graduation probability.
                        </>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default StudentAnalytics;
