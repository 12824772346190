// // ResourcePage.js

// import React, { useState } from 'react';
// import CustomSidebar from '../Sidebar/Sidebar';
// import ResourcesComponent from './ResourcesComponent';
// import EngagementOpportunities from './EngagementOpportunities';

// const ResourcePage = () => {
//   const [activeTab, setActiveTab] = useState('resources');

//   return (
//     <div className="flex min-h-screen">
//       {/* Left sidebar */}
//       <CustomSidebar adjustment="Resources" />

//       {/* Tabs Header (fixed) */}
//       <div className="fixed ml-8 top-14 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-gray-800 z-10 w-full">
//         <div className="flex justify-center">
//           <ul
//             className="flex space-x-4 py-2 text-sm font-medium text-center text-gray-500 dark:text-gray-400"
//             id="default-tab"
//             data-tabs-toggle="#default-tab-content"
//             role="tablist"
//             style={{ listStyleType: 'none' }}
//           >
//             {/* Resources Tab */}
//             <li role="presentation">
//               <button
//                 className={`inline-block py-3 px-6 whitespace-nowrap ${
//                   activeTab === 'resources'
//                     ? 'text-blue-600'
//                     : 'hover:text-gray-600 dark:hover:text-gray-300'
//                 }`}
//                 id="resources-tab"
//                 type="button"
//                 role="tab"
//                 aria-controls="resources"
//                 aria-selected={activeTab === 'resources'}
//                 onClick={() => setActiveTab('resources')}
//               >
//                 Resources
//               </button>
//             </li>

//             {/* Engagement Opportunities Tab */}
//             <li role="presentation">
//               <button
//                 className={`inline-block py-3 px-6 whitespace-nowrap ${
//                   activeTab === 'engagement'
//                     ? 'text-blue-600'
//                     : 'hover:text-gray-600 dark:hover:text-gray-300'
//                 }`}
//                 id="engagement-tab"
//                 type="button"
//                 role="tab"
//                 aria-controls="engagement"
//                 aria-selected={activeTab === 'engagement'}
//                 onClick={() => setActiveTab('engagement')}
//               >
//                 Engagement Opportunities
//               </button>
//             </li>
//           </ul>
//         </div>
//         <div className="border-b border-gray-200 dark:border-gray-700"></div>
//       </div>

//       {/* Render whichever tab is active */}
//       {activeTab === 'resources' && <ResourcesComponent />}
//       {activeTab === 'engagement' && <EngagementOpportunities />}
//     </div>
//   );
// };

// export default ResourcePage;


import React from 'react';
import CustomSidebar from '../Sidebar/Sidebar';
import ResourcesPage from './ResourcesPage';

const ResourcePage = () => {
  return (
    <div className="flex min-h-screen">
      {/* Left sidebar */}
      <CustomSidebar adjustment="Resources" />

      {/* Only Resources Content */}
      <ResourcesPage />
    </div>
  );
};

export default ResourcePage;
