// ResourcesComponent.js

import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebase';
import { TextInput, Spinner } from 'flowbite-react';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  arrayUnion,
  writeBatch,
  query,
  where,
  doc,
} from 'firebase/firestore';
import debounce from 'lodash.debounce';
import { FaSearch } from 'react-icons/fa';

import StudentListing from '../Students/StudentListing/StudentListing';

// 1) Import the new modal
import ResourceDetailsModal from './ResourcesPageComponents/ResourceDetailsModal';

import ResourcesCard from './ResourcesPageComponents/ResourcesCard';

const ResourcesComponent = () => {
  // ----------------------------
  //            STATE
  // ----------------------------
  const [resources, setResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // Create/Edit Resource Form
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newResource, setNewResource] = useState({
    name: '',
    description: '',
    image: '',
    department: '',
    link: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editResourceId, setEditResourceId] = useState(null);

  // Recommend Resource
  const [students, setStudents] = useState([]);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);

  // Loading
  const [assigning, setAssigning] = useState(false);
  const [loading, setLoading] = useState(false);

  // 2) NEW - Details Modal State
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [detailsResource, setDetailsResource] = useState(null);

  // ----------------------------
  //         USE EFFECTS
  // ----------------------------

  // 1) Fetch resources
  useEffect(() => {
    const fetchResources = async () => {
      try {
        const resourcesRef = collection(db, 'resources');
        const snapshot = await getDocs(resourcesRef);
        const resourcesData = snapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setResources(resourcesData);
        setFilteredResources(resourcesData);
      } catch (error) {
        console.error('Error fetching resources:', error);
      }
    };

    fetchResources();
  }, []);

  // 2) Fetch students (for recommending resources)
  useEffect(() => {
    const fetchStudentsData = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error('No authenticated user found.');
          return;
        }

        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('role', '==', 'student'));
        const snapshot = await getDocs(q);

        const firestoreData = snapshot.docs.map((docSnap) => {
          const data = docSnap.data();
          const majors = data.surveyAnswers?.Majors || ['No Major'];
          const minors = data.surveyAnswers?.Minors || ['No Minor'];
          const planned = data.courseProgress?.planned || 0;
          const taken = data.courseProgress?.taken || 0;
          const total = data.courseProgress?.total || 0;

          return {
            id: docSnap.id,
            fromFirestore: true,
            name: `${data.firstName} ${data.lastName}`,
            email: data.email || 'N/A',
            firstName: data.firstName,
            lastName: data.lastName,
            GPA: data.surveyAnswers?.GPA || 'N/A',
            surveyAnswers: data.surveyAnswers,
            resources: data.Resources || [],
            major: majors,
            minor: minors,
            planned: planned,
            taken: taken,
            total: total,
            classesTaken: data.classesTaken || 0,
            classesRegistered: data.classesRegistered || 0,
          };
        });

        setStudents(firestoreData);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    fetchStudentsData();
  }, []);

  // ----------------------------
  //          HANDLERS
  // ----------------------------

  // 1) Debounced Search
  const handleSearch = debounce((term) => {
    if (term) {
      const filtered = resources.filter((res) =>
        res.name.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredResources(filtered);
    } else {
      setFilteredResources(resources);
    }
  }, 300);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    handleSearch(term);
  };

  // 2) Toggle Create Form
  const handleToggleCreateForm = () => {
    setShowCreateForm(!showCreateForm);
    setIsEditing(false);
    setNewResource({
      name: '',
      description: '',
      image: '',
      department: '',
      link: '',
    });
  };

  // 3) Input changes
  const handleInputChange = (e) => {
    setNewResource({
      ...newResource,
      [e.target.name]: e.target.value,
    });
  };

  // 4) Create Resource
  const handleCreateResource = async () => {
    if (!newResource.name || !newResource.description || !newResource.department) {
      alert('Please fill out all required fields!');
      return;
    }
    setLoading(true);
    try {
      const resourcesRef = collection(db, 'resources');
      const docRef = await addDoc(resourcesRef, {
        ...newResource,
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      const createdResource = { id: docRef.id, ...newResource };
      setResources((prev) => [...prev, createdResource]);
      setFilteredResources((prev) => [...prev, createdResource]);

      // reset form
      setShowCreateForm(false);
      setNewResource({
        name: '',
        description: '',
        image: '',
        department: '',
        link: '',
      });
    } catch (error) {
      console.error('Error creating resource:', error);
      alert('Failed to create resource. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // 5) Edit Resource
  const handleEditResource = (resource) => {
    setIsEditing(true);
    setEditResourceId(resource.id);
    setNewResource({
      name: resource.name,
      description: resource.description,
      image: resource.image,
      department: resource.department,
      link: resource.link,
    });
    setShowCreateForm(true);
  };

  // 6) Save Edited Resource
  const handleSaveEditedResource = async () => {
    if (!newResource.name || !newResource.description || !newResource.department) {
      alert('Please fill out all required fields!');
      return;
    }
    setLoading(true);
    try {
      const resourceRef = doc(db, 'resources', editResourceId);
      await updateDoc(resourceRef, {
        ...newResource,
        updatedAt: new Date(),
      });

      setResources((prevResources) =>
        prevResources.map((res) =>
          res.id === editResourceId ? { id: res.id, ...newResource } : res
        )
      );
      setFilteredResources((prevResources) =>
        prevResources.map((res) =>
          res.id === editResourceId ? { id: res.id, ...newResource } : res
        )
      );
      setShowCreateForm(false);
      setIsEditing(false);
      setEditResourceId(null);
      setNewResource({
        name: '',
        description: '',
        image: '',
        department: '',
        link: '',
      });
    } catch (error) {
      console.error('Error updating resource:', error);
      alert('Failed to update resource. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // 7) Delete Resource
  const handleDeleteResource = async (resourceId) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this resource? This action cannot be undone.'
    );
    if (!confirmDelete) return;

    setLoading(true);
    try {
      const resourceRef = doc(db, 'resources', resourceId);
      await deleteDoc(resourceRef);

      setResources((prev) => prev.filter((res) => res.id !== resourceId));
      setFilteredResources((prev) => prev.filter((res) => res.id !== resourceId));
    } catch (error) {
      console.error('Error deleting resource:', error);
      alert('Failed to delete resource. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // 8) Recommend Resource
  const handleRecommend = (resource) => {
    setSelectedResource(resource);
    setShowAssignModal(true);
  };

  // 9) Close Modals
  const closeModal = () => {
    setShowAssignModal(false);
    setShowCreateForm(false);
    setSelectedResource(null);
    setIsEditing(false);
    setEditResourceId(null);
    setNewResource({
      name: '',
      description: '',
      image: '',
      department: '',
      link: '',
    });
    setShowDetailsModal(false);
    setDetailsResource(null);
  };

  // 10) Handle successful resource recommendation
  const handleRecommendSuccess = async (selectedStudentIds) => {
    if (!selectedResource) return;

    setAssigning(true);
    try {
      const batch = writeBatch(db);
      selectedStudentIds.forEach((studentId) => {
        const studentRef = doc(db, 'users', studentId);
        batch.update(studentRef, {
          Resources: arrayUnion(selectedResource.id),
        });
      });

      await batch.commit();

      alert(
        `Resource "${selectedResource.name}" recommended successfully to ${selectedStudentIds.length} student(s).`
      );
      closeModal();
    } catch (error) {
      console.error('Error recommending resources:', error);
      alert('Failed to recommend resource. Please try again.');
    } finally {
      setAssigning(false);
    }
  };

  // 11) NEW - Show details modal
  const handleShowDetails = (resource) => {
    setDetailsResource(resource);
    setShowDetailsModal(true);
  };

  // ----------------------------
  //          RENDER
  // ----------------------------
  return (
    <div className="flex-1 p-4 ml-20 flex flex-col">
      {/* Header */}
      <div className="text-center mb-4">
        <h1 className="text-3xl font-bold mb-2 mt-3">Resources</h1>
        <p className="text-base text-gray-600 mb-2">
          Welcome to the University Resources Page, your go-to hub for campus services.
        </p>
      </div>

      {/* Search & Create Buttons */}
      <div className="flex flex-col sm:flex-row items-center gap-4 mb-4">
        {/* Make the TextInput span most of the width */}
        <div className="flex-1 w-full">
          <TextInput
            type="text"
            placeholder="Search resources..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handleSearch(e.target.value);
            }}
            icon={FaSearch}
            className="w-full"
          />
        </div>
        <button
          type="button"
          onClick={handleToggleCreateForm}
          className="text-blue-700 hover:!text-white border border-blue-700 hover:!bg-blue-800 
                     focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg 
                     text-sm px-5 py-2 text-center dark:border-blue-500 dark:text-blue-500 
                     dark:hover:!text-white dark:hover:!bg-blue-500 dark:focus:ring-blue-800"
        >
          {showCreateForm && !isEditing ? 'Cancel' : 'New Resource'}
        </button>
      </div>

      {/* Create/Edit Resource Modal */}
      {showCreateForm && (
        <div
          id="crud-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 justify-center 
            items-center w-full md:inset-0 h-full max-h-full overflow-y-auto 
            overflow-x-hidden flex bg-black bg-opacity-50 backdrop-blur-sm"
          onClick={closeModal}
        >
          <div className="relative p-4 w-full max-w-md max-h-full">
            <div
              className="relative bg-white rounded-lg shadow dark:bg-gray-700"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  {isEditing ? 'Edit Resource' : 'Create New Resource'}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 
                    rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center 
                    dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={closeModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <form className="p-4 md:p-5">
                <div className="grid gap-4 mb-4">
                  <div className="col-span-2">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Resource Name
                    </label>
                    <TextInput
                      name="name"
                      placeholder="Enter resource name"
                      value={newResource.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Description
                    </label>
                    <TextInput
                      name="description"
                      placeholder="Enter description"
                      value={newResource.description}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="image"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Image URL
                    </label>
                    <TextInput
                      name="image"
                      placeholder="Enter image URL"
                      value={newResource.image}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="department"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Department/School
                    </label>
                    <TextInput
                      name="department"
                      placeholder="Enter department or school"
                      value={newResource.department}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="link"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Resource Link
                    </label>
                    <TextInput
                      name="link"
                      placeholder="Enter resource link"
                      value={newResource.link}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="flex justify-center">
                  <button
                    type="button"
                    className="text-white inline-flex items-center bg-blue-700 
                      hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 
                      font-medium rounded-lg text-sm px-5 py-2 text-center dark:bg-blue-600 
                      dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={isEditing ? handleSaveEditedResource : handleCreateResource}
                  >
                    {isEditing ? 'Save Changes' : 'Create Resource'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Resource Cards */}
      <div className="flex-1 overflow-y-auto">
        {/* Force 4 columns on larger screens */}
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-4">
          {filteredResources.length > 0 ? (
            filteredResources.map((resource) => (
              <ResourcesCard
                key={resource.id}
                resource={resource}
                handleShowDetails={handleShowDetails}
              />
            ))
          ) : (
            <div className="col-span-full text-center text-gray-500">
              {resources.length === 0 ? <Spinner aria-label="Loading" /> : 'No resources found.'}
            </div>
          )}
        </div>
      </div>

      {/* Details Modal */}
      {showDetailsModal && detailsResource && (
        <ResourceDetailsModal
          resource={detailsResource}
          onClose={closeModal}
          onEdit={() => handleEditResource(detailsResource)}
          onDelete={() => handleDeleteResource(detailsResource.id)}
          onRecommend={() => handleRecommend(detailsResource)}
          isLoading={assigning || loading}
        />
      )}

      {/* Recommend Resource Modal */}
      {showAssignModal && selectedResource && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 
            backdrop-blur-sm"
          onClick={closeModal}
        >
          <div className="w-full mx-4 p-6 overflow-auto max-h-full">
            <div className="overflow-auto shadow-md" onClick={(e) => e.stopPropagation()}>
              <StudentListing
                students={students}
                onRecommendSuccess={handleRecommendSuccess}
                selectedResource={selectedResource}
                adjustment="Resources"
                onClose={closeModal}
              />
            </div>
          </div>
        </div>
      )}

      {/* Loading Indicator */}
      {(assigning || loading) && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 flex flex-col items-center">
            <Spinner aria-label="Processing" />
            <p className="mt-4 text-center">
              {assigning
                ? 'Recommending resources to selected students...'
                : 'Processing...'}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResourcesComponent;
