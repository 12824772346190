// src/components/Dashboard/Cards/PersistencePredictionCard.jsx
import React, { useState, useEffect, useCallback } from 'react';
// import { getFirestore, collection, getDocs } from 'firebase/firestore'; // Commented out for fake data
// import { useAuth } from '../../../../contexts/AuthContext'; // Commented out if not needed for fake data
import DashboardCard from './DashboardCard';

// Chart
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { toast } from 'react-toastify';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const PersistencePredictionCard = ({ onHide }) => {
  // const { currentUser } = useAuth(); // Commented out if not needed for fake data
  // const db = getFirestore(); // Commented out for fake data

  const [persistenceCounts, setPersistenceCounts] = useState({
    veryLow: 12,
    low: 18,
    moderate: 67,
    high: 233,
    veryHigh: 37,
  });
  const [isLoading, setIsLoading] = useState(true);

  // Fixed data for “Students in Need”
  const [studentsInNeed, setStudentsInNeed] = useState(100); // Example fixed value
  const [studentsAddressed, setStudentsAddressed] = useState(50); // Example fixed value

  const fetchPersistenceData = useCallback(async () => {
    setIsLoading(true);
    try {
    // Fake data logic
      // Set fixed distribution counts
      const fixedDistribution = {
        veryLow: 12,
        low: 18,
        moderate: 67,
        high: 233,
        veryHigh: 37,
      };

      setPersistenceCounts(fixedDistribution);

      // Set fixed student data
      setStudentsInNeed(63);
      setStudentsAddressed(40);

      // If you have any additional fake data, set them here
    } catch (err) {
      console.error('[PersistencePredictionCard] Error:', err);
      toast.error('Failed to fetch persistence data.');
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchPersistenceData();
  }, [fetchPersistenceData]);

  const totalStudents = 367; // Fixed total as per requirement
  const addressedPercentage =
    studentsInNeed > 0 ? Math.round((studentsAddressed / studentsInNeed) * 100) : 0;

  // Donut Chart config
  const chartData = {
    labels: ['Very Low', 'Low', 'Moderate', 'High', 'Very High'],
    datasets: [
      {
        data: [
          persistenceCounts.veryLow,
          persistenceCounts.low,
          persistenceCounts.moderate,
          persistenceCounts.high,
          persistenceCounts.veryHigh,
        ],
        backgroundColor: [
          '#e11d48', // Very Low - red
          '#fb923c', // Low - orange
          '#facc15', // Moderate - yellow
          '#4ade80', // High - green
          '#38bdf8', // Very High - blue
        ],
        hoverOffset: 4,
        borderWidth: 0,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '65%',
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: (ctx) => {
            const label = ctx.label || '';
            const value = ctx.raw || 0;
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  return (
    <DashboardCard title="Predictive Insights" onHide={onHide} isLoading={isLoading}>
      {!isLoading && (
        <div className="pl-4 pr-4 pb-2">
          {/* Subtitle */}
          <p className="text-sm text-gray-500 mb-4">
            The Persistence Prediction is a predictive indicator of a student's likelihood
            to re-enroll and stay enrolled at your institution.
          </p>

          {/* Flex container, column on small screens, row on medium+ */}
          <div className="flex flex-col md:flex-row items-start gap-6">

            {/* LEFT: Donut + Legend */}
            <div className="flex flex-col md:flex-row items-start gap-4 w-full md:w-auto">
              {/* Donut (responsive size) */}
              <div className="relative w-32 h-32 sm:w-40 sm:h-40 flex-shrink-0">
                <Doughnut data={chartData} options={chartOptions} />
                {/* Center label => total students */}
                <div className="absolute inset-0 flex flex-col items-center justify-center pointer-events-none">
                  <span className="text-xl font-semibold">{totalStudents}</span>
                  <span className="text-sm text-gray-600">Students</span>
                </div>
              </div>

              {/* Legend */}
              <div className="text-sm">
                <div className="font-semibold mb-2">
                  Prediction Distribution | Fall 2024 - Spring
                </div>
                <div className="space-y-1">
                  {[
                    { label: 'Very Low', count: persistenceCounts.veryLow, color: 'bg-red-500' },
                    { label: 'Low', count: persistenceCounts.low, color: 'bg-orange-400' },
                    { label: 'Moderate', count: persistenceCounts.moderate, color: 'bg-yellow-400' },
                    { label: 'High', count: persistenceCounts.high, color: 'bg-green-400' },
                    { label: 'Very High', count: persistenceCounts.veryHigh, color: 'bg-blue-400' }, // Ensured non-zero count
                  ].map((item) => (
                    <div key={item.label} className="flex justify-between items-center">
                      <div className="flex items-center">
                        <span className={`w-3 h-3 ${item.color} rounded-full mr-2`} />
                        <span>{item.label}</span>
                      </div>
                      <span>{item.count}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* RIGHT: "Students in Need" & "Addressed Progress" */}
            <div className="flex-1 flex flex-col items-start md:items-end">
              {/* Big red number + arrow */}
              <div className="mb-2 flex items-center space-x-2 text-red-600">
                <span className="text-xl">&#x2193;</span>
                <span className="text-3xl font-bold">{studentsInNeed}</span>
              </div>
              <p className="text-sm text-gray-500 max-w-sm mb-3">
                Students experienced a 5% or more drop this week in their persistence prediction.&nbsp; 
                <a href="#details" className="text-blue-600 underline">
                  View details
                </a>
              </p>

              {/* Addressed Progress */}
              <div className="w-full sm:w-auto">
                <div className="text-sm font-semibold mb-1">Addressed Progress</div>
                <div className="w-full sm:w-48 bg-gray-200 h-4 rounded relative mb-1">
                  <div
                    className="bg-blue-500 h-4 rounded"
                    style={{ width: `${addressedPercentage}%` }}
                  />
                </div>
                <div className="text-xs text-gray-600">
                  Students Addressed: {studentsAddressed} ({addressedPercentage}%) / {studentsInNeed} students
                </div>
              </div>
            </div>

          </div>
        </div>
      )}
    </DashboardCard>
  );
};

export default PersistencePredictionCard;
