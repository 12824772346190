// StudentPersistence.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import CustomSidebar from '../Sidebar/Sidebar';
import EngagementOpportunities from './EngagementOpportunities';

// ------------------- Helper functions -------------------
const getBorderColorClass = (category) => {
  switch (category) {
    case 'moderate':
      return 'border-t-4 border-yellow-500';
    case 'low':
      return 'border-t-4 border-orange-500';
    case 'veryLow':
      return 'border-t-4 border-red-800';
    default:
      return 'border-t-4 border-green-500'; 
  }
};

const formatPrediction = (prediction) => {
  switch (prediction) {
    case 'veryLow':
      return 'Very Low';
    case 'low':
      return 'Low';
    case 'moderate':
      return 'Moderate';
    case 'high':
      return 'High';
    default:
      return prediction?.charAt(0).toUpperCase() + prediction?.slice(1);
  }
};

const getInitials = (firstName = '', lastName = '') => {
  const fInitial = firstName.charAt(0).toUpperCase() || '';
  const lInitial = lastName.charAt(0).toUpperCase() || '';
  return `${fInitial}${lInitial}`;
};

const getTextColorClass = (prediction) => {
  switch (prediction) {
    case 'veryLow':
      return 'text-red-800 font-semibold';
    case 'low':
      return 'text-orange-500 font-semibold';
    case 'moderate':
      return 'text-yellow-500 font-semibold';
    default:
      return 'text-green-600 font-semibold';
  }
};

const StudentPersistence = () => {
  const [predictors, setPredictors] = useState([]);
  const [activeTab, setActiveTab] = useState('predictors');
  const [filters, setFilters] = useState({
    veryLow: false,
    low: false,
    moderate: false,
    high: false,
  });
  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  // Initialize opportunityFilter state from navigation state
  const [opportunityFilter, setOpportunityFilter] = useState(location.state?.filterOpportunity);

  useEffect(() => {
    const fetchPredictors = async () => {
      try {
        const predictorsSnapshot = await getDocs(collection(db, 'Persistence'));
        const predictorsList = await Promise.all(
          predictorsSnapshot.docs.map(async (docSnapshot) => {
            const predictorData = { id: docSnapshot.id, ...docSnapshot.data() };
            try {
              const userDocRef = doc(db, 'users', predictorData.id);
              const userDocSnap = await getDoc(userDocRef);
              if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                const surveyAnswers = userData?.surveyAnswers;
                if (surveyAnswers?.Majors && Array.isArray(surveyAnswers.Majors)) {
                  predictorData.major = surveyAnswers.Majors.join(' ');
                }
              }
            } catch (userError) {
              console.error('Error fetching user major for ID', predictorData.id, userError);
            }
            return predictorData;
          })
        );
        setPredictors(predictorsList);
      } catch (error) {
        console.error('Error fetching predictors:', error);
      }
    };

    fetchPredictors();
  }, []);

  const handleFilterChange = (prediction) => {
    setFilters((prev) => ({
      ...prev,
      [prediction]: !prev[prediction],
    }));
  };

  const filteredPredictors = React.useMemo(() => {
    const anyFilterChecked = Object.values(filters).some(Boolean);

    return predictors.filter((p) => {
      const matchesFilter = anyFilterChecked ? filters[p.prediction] : true;
      const fullName = `${p.firstName} ${p.lastName}`.toLowerCase();
      const matchesSearch = fullName.includes(searchQuery.toLowerCase());
      const matchesOpportunity = opportunityFilter
        ? p.applicableEngagements?.some(e => e.name === opportunityFilter)
        : true;

      return matchesFilter && matchesSearch && matchesOpportunity;
    });
  }, [predictors, filters, searchQuery, opportunityFilter]);

  return (
    <div className="flex min-h-screen w-full">
      {/* Sidebar */}
      <div className="w-1/12">
        <CustomSidebar adjustment="PERSISTENCE PREDICTIONS" />
      </div>

      {/* Main Content Area */}
      <div className="flex-1 p-2">
        {/* Top Navigation Bar */}
        <div className="bg-white dark:bg-gray-800 w-full">
          <div className="flex justify-center">
            <ul
              className="flex space-x-4 py-2 mt-4 text-sm font-medium text-center text-gray-500 dark:text-gray-400"
              role="tablist"
            >
              <li role="presentation">
                <button
                  className={`inline-block py-3 px-6 whitespace-nowrap ${
                    activeTab === 'predictors'
                      ? 'text-blue-600'
                      : 'hover:text-gray-600 dark:hover:text-gray-300'
                  }`}
                  onClick={() => setActiveTab('predictors')}
                >
                  Persistence Predictors
                </button>
              </li>
              <li role="presentation">
                <button
                  className={`inline-block py-3 px-6 whitespace-nowrap ${
                    activeTab === 'engagement'
                      ? 'text-blue-600'
                      : 'hover:text-gray-600 dark:hover:text-gray-300'
                  }`}
                  onClick={() => setActiveTab('engagement')}
                >
                  Engagement Opportunities
                </button>
              </li>
            </ul>
          </div>
          <div className="border-b border-gray-200 dark:border-gray-700"></div>
        </div>

        {/* Content Below Navigation */}
        <div className="mt-4">
          {activeTab === 'predictors' ? (
            <>
              <h2 className="text-2xl font-light mb-4">Student Persistence Predictions</h2>

              {/* Filter + Search Bar Card */}
              <div className="border border-gray-200 w-full bg-white shadow-md rounded-md p-4 mb-6 flex flex-wrap items-center">
                <div className="flex items-center space-x-6">
                  <span className="font-medium text-gray-700">
                    Filter by persistence prediction:
                  </span>
                  {['veryLow', 'low', 'moderate', 'high'].map((key) => (
                    <label key={key} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded
                                   focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800
                                   focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={filters[key]}
                        onChange={() => handleFilterChange(key)}
                      />
                      <span className={getTextColorClass(key)}>
                        {formatPrediction(key)}
                      </span>
                    </label>
                  ))}
                </div>

                {/* Search Bar */}
                <div className="ml-auto mt-2 md:mt-0">
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search students..."
                    className="border rounded px-4 py-2 text-sm focus:outline-none
                               focus:ring-2 focus:ring-blue-400 transition-colors w-64"
                  />
                </div>
              </div>

              {/* Applied Filters Tags */}
              {(Object.values(filters).some(Boolean) || opportunityFilter) && (
                <div className="mb-4">
                  {Object.entries(filters).filter(([_, val]) => val).map(([key]) => (
                    <span
                      key={key}
                      className="inline-flex items-center bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded"
                    >
                      {formatPrediction(key)}
                      <button
                        type="button"
                        onClick={() => handleFilterChange(key)}
                        className="ml-1 text-blue-500 hover:text-blue-700"
                      >
                        ×
                      </button>
                    </span>
                  ))}
                  {opportunityFilter && (
                    <span
                      className="inline-flex items-center bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded"
                    >
                      {opportunityFilter}
                      <button
                        type="button"
                        onClick={() => setOpportunityFilter(null)}
                        className="ml-1 text-green-500 hover:text-green-700"
                      >
                        ×
                      </button>
                    </span>
                  )}
                </div>
              )}

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mr-3 gap-4">
                {filteredPredictors.map((predictor) => (
                  <div
                    key={predictor.id}
                    className={`min-h-[400px] w-full bg-white rounded shadow-md py-2 px-2
                                flex flex-col items-center justify-between
                                ${getBorderColorClass(predictor.prediction)}
                                cursor-pointer hover:shadow-lg transition transform hover:scale-105`}
                    onClick={() => navigate(`/Profile/${predictor.studentId}`)}
                  >
                    <div className="flex flex-col items-center mb-2">
                      <div className="w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center mb-2">
                        <span className="text-white font-bold">
                          {getInitials(predictor.firstName, predictor.lastName)}
                        </span>
                      </div>
                      <h2 className="text-xl font-bold text-gray-800 text-center">
                        {predictor.firstName} {predictor.lastName}
                      </h2>
                      {predictor.major && (
                        <p className="text-sm text-gray-500">
                          {predictor.major}
                        </p>
                      )}
                    </div>

                    <hr className="w-full border-gray-300 mb-2" />

                    <div className="flex w-full justify-around px-2 mb-2">
                      <div className="flex flex-col items-center">
                        <span className="text-xl font-semibold text-gray-800">
                          {predictor.applicableEngagements?.length ?? 0}
                        </span>
                        <span className="text-xs text-gray-500">
                          Opportunities
                        </span>
                      </div>
                      <div className="flex flex-col items-center">
                        <span
                          className={`text-xl font-semibold ${
                            predictor.prediction === 'low' || predictor.prediction === 'veryLow'
                              ? 'text-red-600'
                              : 'text-green-600'
                          }`}
                        >
                          {formatPrediction(predictor.prediction)}
                        </span>
                        <span className="text-xs text-gray-500">
                          Persistence Prediction
                        </span>
                      </div>
                    </div>

                    {predictor?.applicableEngagements?.length > 0 && (
                      <div className="mt-2 w-full px-2">
                        {predictor.applicableEngagements.map((engagement, idx) => (
                          <span
                            key={idx}
                            className="block w-full bg-gray-100 text-gray-700 text-xs font-semibold py-1 px-2
                                       rounded-sm mb-2"
                          >
                            {engagement.name}
                          </span>
                        ))}
                      </div>
                    )}

                    <div className="flex-grow" />

                    <div className="flex justify-center mt-2">
                      <p className="text-md text-gray-700">
                        GPA:&nbsp;
                        <strong>
                          {predictor.gpaScore ? predictor.gpaScore.toFixed(2) : 'N/A'}
                        </strong>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : activeTab === 'engagement' ? (
            <EngagementOpportunities />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default StudentPersistence;
