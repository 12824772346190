// ResourceDetailsModal.js

import React from 'react';
import { Spinner } from 'flowbite-react';

const ResourceDetailsModal = ({
  resource,
  onClose,
  onEdit,
  onDelete,
  onRecommend,
  isLoading,
}) => {
  if (!resource) return null;

  const handleStopPropagation = (e) => e.stopPropagation();

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
      onClick={onClose}
    >
      <div
        className="relative p-4 w-full max-w-md max-h-full bg-white rounded-lg shadow dark:bg-gray-700"
        onClick={handleStopPropagation}
      >
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b dark:border-gray-600">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            {resource.name}
          </h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 
                       rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center 
                       dark:hover:bg-gray-600 dark:hover:text-white"
            onClick={onClose}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        {/* Body */}
        <div className="p-4 space-y-4">
          <img
            className="w-full h-40 object-cover rounded"
            src={resource.image || 'https://via.placeholder.com/300x200'}
            alt={resource.name}
          />
          <p className="text-sm text-gray-700 dark:text-gray-300">
            <strong>Description:</strong> {resource.description}
          </p>
          {resource.link && (
            <p className="text-sm">
              <strong>Link:</strong>{' '}
              <a
                href={resource.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {resource.link}
              </a>
            </p>
          )}
          <p className="text-sm text-gray-700 dark:text-gray-300">
            <strong>Department:</strong> {resource.department}
          </p>
        </div>

        {/* Footer - Action Buttons */}
        <div className="flex justify-between items-center p-4 border-t dark:border-gray-600">
          <div>
            <button
              type="button"
              onClick={onRecommend}
              className="px-3 py-2 text-sm font-medium text-gray-900 bg-white 
                         border border-gray-200 rounded-lg hover:!bg-gray-100 
                         hover:!text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 
                         focus:!text-blue-700 dark:bg-gray-800 dark:border-gray-700 
                         dark:text-white dark:hover:!text-white dark:hover:!bg-gray-700 
                         dark:focus:ring-blue-500 dark:focus:!text-white mr-2"
            >
              Recommend
            </button>

            <button
              type="button"
              onClick={onEdit}
              className="px-3 py-2 text-sm font-medium text-gray-900 bg-white 
                         border border-gray-200 rounded-lg hover:!bg-gray-100 
                         hover:!text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 
                         focus:!text-blue-700 dark:bg-gray-800 dark:border-gray-700 
                         dark:text-white dark:hover:!text-white dark:hover:!bg-gray-700 
                         dark:focus:ring-blue-500 dark:focus:!text-white mr-2"
            >
              Edit
            </button>

            <button
              type="button"
              onClick={onDelete}
              className="px-3 py-2 text-sm font-medium text-gray-900 bg-white 
                         border border-gray-200 rounded-lg hover:!bg-gray-100 
                         hover:!text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 
                         focus:!text-blue-700 dark:bg-gray-800 dark:border-gray-700 
                         dark:text-white dark:hover:!text-white dark:hover:!bg-gray-700 
                         dark:focus:ring-blue-500 dark:focus:!text-white"
            >
              Delete
            </button>
          </div>

          {isLoading && <Spinner aria-label="Processing" />}
        </div>
      </div>
    </div>
  );
};

export default ResourceDetailsModal;
