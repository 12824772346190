import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { auth, functions } from '../components/firebase';
import { httpsCallable } from 'firebase/functions';
import LZString from 'lz-string';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../components/firebase';  // adjust the path as needed


const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [roleLoading, setRoleLoading] = useState(true);
  const [generalDataLoading, setGeneralDataLoading] = useState(true);
  const [userRole, setUserRole] = useState('');
  const [fetchedCourseData, setFetchedCourseData] = useState(null);
  const [fetchedPrereqData, setFetchedPrereqData] = useState(null);
  const [fetchedElective, setFetchedElective] = useState(null);
  const [fetchedMajorRecs, setFetchedMajorRecs] = useState(null);
  const [fetchedSurveyData, setFetchedSurveyData] = useState(null);
  const [pathwayData, setPathwayData] = useState(null);
  const [pathwayGroupingsData, setPathwayGroupingsData] = useState(null);
  const [faculties, setFaculties] = useState([]);
  const [majors, setMajors] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [majorData, setMajorData] = useState(null);
  const [fetchedMinors, setFetchedMinors] = useState(null);
  const [fetchedMajors, setFetchedMajors] = useState(null);
  const [categories, setCategories] = useState(null);
  const isUserDataFetched = useRef(false);
  const isMajorDataFetched = useRef(false);
  const [signingUp, setSigningUp] = useState(false);

  // Function to wait for the role claim
  const waitForRoleClaim = async (user, timeout = 20000, interval = 1000) => {
    const startTime = Date.now();
    console.log('Waiting for role claim...');

    while (Date.now() - startTime < timeout) {
        const tokenResult = await user.getIdTokenResult(true);
        
        if (tokenResult && tokenResult.claims && tokenResult.claims.role) {
            console.log('Role found:', tokenResult.claims.role);
            return tokenResult.claims.role;
        }

        console.log('Role not found, waiting...');
        await new Promise((resolve) => setTimeout(resolve, interval));
    }

    throw new Error('Role claim not set within timeout period.');
  };

  // Fetch general data using Cloud Function
  const fetchGeneralData = useCallback(async () => {
    const fetchGeneralDataFunction = httpsCallable(functions, 'fetchGeneralData2');
    try {
      console.log('Environment variable: ', process.env.REACT_APP_SCHOOL);
      const school = process.env.REACT_APP_SCHOOL || 'SFU';
      console.log('Calling fetchGeneralData function');
      const result = await fetchGeneralDataFunction(school);
      console.log('fetchGeneralData function result:', result);

      const {
        allCourses,
        prereqs,
        totalElectivesData,
        surveyData,
        pathwayData,
        pathwayGroupingsData,
        faculties,
        tasks,
        majorRecsData,
        minors,
        majors,
        electiveCategories,
      } = result.data;

      // Compress general data and cache it in local storage
      const compressedAllCourses = LZString.compress(JSON.stringify(allCourses));
      localStorage.setItem(
        'appDataCache',
        JSON.stringify({
          allCourses: compressedAllCourses,
          prereqs,
          totalElectivesData,
          surveyData,
          pathwayData,
          pathwayGroupingsData,
          faculties,
          tasks,
          majorRecsData,
          timestamp: Date.now(),
          minors,
          majors,
          electiveCategories,
        })
      );

      setFetchedCourseData(allCourses);
      setFetchedPrereqData(prereqs);
      setFetchedElective(totalElectivesData);
      setFetchedSurveyData(surveyData);
      setPathwayData(pathwayData);
      setPathwayGroupingsData(pathwayGroupingsData);
      setFaculties(faculties);
      setMajors(pathwayData);
      setTasks(tasks);
      setFetchedMajorRecs(majorRecsData);
      setFetchedMinors(minors);
      setFetchedMajors(majors);
      setGeneralDataLoading(false);
      setCategories(electiveCategories);
    } catch (error) {
      console.error('Error calling fetchGeneralData function:', error);
    }
  }, []);

  // Fetch major data using Cloud Function
  const fetchMajorData = useCallback(async (major, school) => {
    if (isMajorDataFetched.current) return;
    isMajorDataFetched.current = true;

    const fetchMajorDataFunction = httpsCallable(functions, 'fetchMajorData');
    try {
      console.log(`Calling fetchMajorData function with major: ${major} and school: ${school}`);
      const result = await fetchMajorDataFunction({ major, school });
      console.log('Data received from fetchMajorData function:', result.data);

      // Cache major data in local storage
      localStorage.setItem(
        'majorDataCache',
        JSON.stringify({
          majorData: result.data.majorData,
          timestamp: Date.now(),
        })
      );

      setMajorData(result.data.majorData);
    } catch (error) {
      console.error('Error calling fetchMajorData function:', error);
    }
  }, []);

  // Fetch user data using Cloud Function
  const fetchUserData = useCallback(
    async (user = false) => {
      if (!user || !user.uid) {
        console.error('No user object provided to fetchUserData.');
        return;
      }
  
      if (isUserDataFetched.current) {
        console.log('User data has already been fetched. Skipping fetchUserData call.');
        return;
      }
  
      console.log('Fetching user data');
  
      const fetchUserDataFunction = httpsCallable(functions, 'fetchUserData');
      try {
        console.log('Calling fetchUserData function with user ID:', user.uid);
        const result = await fetchUserDataFunction({ userId: user.uid });
        const { userData } = result.data;
        console.log('Data received from fetchUserData function:', result.data);
  
        const updatedUser = { uid: user.uid, ...user, ...userData };
  
        // Cache user data in local storage
        localStorage.setItem(
          'userDataCache',
          JSON.stringify({
            userData: updatedUser,
            timestamp: Date.now(),
          })
        );
  
        setCurrentUser(updatedUser);
  
        console.log('User data:', userData);
        console.log('Survey Answers:', userData.surveyAnswers);
  
        // Fetch major data if major is available in surveyAnswers
        if (userData.surveyAnswers && userData.surveyAnswers.Major) {
          const major = userData.surveyAnswers.Major;
          const school = process.env.REACT_APP_SCHOOL || 'SFU';
          console.log(`Fetching major data for major: ${major} and school: ${school}`);
          await fetchMajorData(major, school);
        }
  
        // Set the flag only after successful fetch
        isUserDataFetched.current = true;
        console.log('User data fetched successfully');
        return updatedUser;
      } catch (error) {
        console.error('Error calling fetchUserData function:', error);
        // Do not set isUserDataFetched.current to true, allowing retries
        return user;
      }
    },
    [fetchMajorData]
  );

  // Signup function
  function signup(email, password) {
    setSigningUp(true);
    return auth.createUserWithEmailAndPassword(email, password);
  }

  // Login function
  function login(email, password) {
    setSigningUp(false);
    return auth.signInWithEmailAndPassword(email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const now = new Date();
        const formattedDate = now.toLocaleDateString('en-US', { 
          year: 'numeric', 
          month: 'short', 
          day: 'numeric' 
        });
        // Update lastLoginDate in the user's Firestore document
        return updateDoc(userRef, { lastLoginDate: formattedDate })
          .then(() => userCredential);
      }
      return userCredential;
    })
    .catch((error) => {
      console.error('Login or Firestore update error:', error);
      throw error;
    });
  }

  // Logout function
  function logout() {
    setCurrentUser(null);
    setUserRole('');
    localStorage.removeItem('userDataCache');
    localStorage.removeItem('majorDataCache');
    isUserDataFetched.current = false;
    isMajorDataFetched.current = false;
    console.clear();
    return auth.signOut();
  }

  // Password reset function
  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  // Update email function
  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  // Update password function
  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  // Use effect for initial data fetch and authentication management
  useEffect(() => {
    let isMounted = true;
  
    // Initial data fetch and caching
    const fetchInitialGeneralData = async () => {
      const cachedData = JSON.parse(localStorage.getItem('appDataCache'));
      const hundredHours = 360000000;
  
      if (cachedData) {
        if (Date.now() - cachedData.timestamp < hundredHours) {
          console.log('Using cached general data');
          if (isMounted) {
            const decompressedAllCourses = JSON.parse(LZString.decompress(cachedData.allCourses));
            setFetchedCourseData(decompressedAllCourses);
            setFetchedPrereqData(cachedData.prereqs);
            setFetchedElective(cachedData.totalElectivesData);
            setFetchedSurveyData(cachedData.surveyData);
            setPathwayData(cachedData.pathwayData);
            setPathwayGroupingsData(cachedData.pathwayGroupingsData)
            setFaculties(cachedData.faculties);
            setMajors(cachedData.pathwayData);
            setTasks(cachedData.tasks);
            setFetchedMajorRecs(cachedData.majorRecsData);
            setFetchedMinors(cachedData.minors);
            setFetchedMajors(cachedData.majors);
            setGeneralDataLoading(false);
            setCategories(cachedData.electiveCategories);
          }
        } else {
          console.log('Cached general data has expired, fetching new data');
          await fetchGeneralData();
        }
      } else {
        console.log('No cached general data found, fetching new data');
        await fetchGeneralData();
      }
    };

    fetchInitialGeneralData();

    // Handle user authentication state changes
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      console.log('Auth state changed:', user);
      if (user && isMounted) {
        console.log('User object from Firebase:', user);

        await user.getIdToken(true);
        try {
          setAuthLoading(true);
          setRoleLoading(true);

          const role = await waitForRoleClaim(user);
          setUserRole(role);

          const fetchedUserData = await fetchUserData(user);

          if (isMounted) {
            setCurrentUser({
              ...fetchedUserData,
              firebaseUser: user,
              claims: { ...fetchedUserData.claims, role },
            });
          }

          // Fetch major data if not cached or expired
          const cachedMajorData = JSON.parse(localStorage.getItem('majorDataCache'));
          const hundredHours = 360000000;
          if (cachedMajorData && Date.now() - cachedMajorData.timestamp < hundredHours) {
            console.log('Using cached major data');
            if (isMounted) {
              setMajorData(cachedMajorData.majorData);
            }
          } else {
            if (fetchedUserData && fetchedUserData.surveyAnswers && fetchedUserData.surveyAnswers.Major) {
              const major = fetchedUserData.surveyAnswers.Major;
              const school = process.env.REACT_APP_SCHOOL || 'SFU';
              console.log(`Fetching new major data for major: ${major} and school: ${school}`);
              await fetchMajorData(major, school);
            }
          }
        } catch (error) {
          console.error('Error fetching role or user data:', error);
        } finally {
          if (isMounted) setAuthLoading(false);
          if (isMounted) setRoleLoading(false);
        }
      } else if (isMounted) {
        console.log('No user authenticated');
        setCurrentUser(null);
        setAuthLoading(false);
        setRoleLoading(false);
      }
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [fetchGeneralData, fetchUserData, fetchMajorData]);

  const value = {
    currentUser,
    fetchedCourseData,
    fetchedPrereqData,
    fetchedElective,
    fetchedSurveyData,
    pathwayData,
    pathwayGroupingsData,
    faculties,
    majors,
    tasks,
    fetchedMajorRecs,
    majorData,
    fetchedMinors,
    fetchedMajors,
    categories,
    fetchUserData,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    authLoading,
    roleLoading,
    userRole,
    setUserRole,
    isUserDataFetched,
  };

  return (
    <AuthContext.Provider value={value}>
      {!authLoading && !roleLoading ? (
        children
      ) : (
        <div role="status" className="flex justify-center items-center min-h-screen">
          <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </AuthContext.Provider>
  );
}
