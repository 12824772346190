import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import NavBar from '../NavBar';
import CoursesListing from './CoursesListing';
import { useNavigate, useLocation } from 'react-router-dom';
import { useClassContext } from '../../contexts/ClassContext';
import ClassCart from './ClassCart';
import { db } from '../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import MultiSelect from './MultiSelect';

const ClassSearch = ({ adjustment }) => {
  const { fetchedCourseData, fetchedMajorRecs } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const yearNumber = parseInt(searchParams.get('yearNumber'));
  const semesterFromLink = searchParams.get('semester');

  const { currentUser } = useAuth();
  const history = useNavigate();
  const sentinelRef = useRef(null);

  const { selectedClasses, setSelectedClasses } = useClassContext();
  const [classesInCart, setClassesInCart] = useState([]);

  const [displayedData, setDisplayedData] = useState([]);
  const [currentBatch, setCurrentBatch] = useState(1);
  const [displayedCourseCount, setDisplayedCourseCount] = useState(0);

  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Multi-select filter states
  const [levelFilter, setLevelFilter] = useState([]);
  const [majorFilter, setMajorFilter] = useState([]);
  const [creditsFilter, setCreditsFilter] = useState([]);

  const [userRecsData, setUserRecsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [finalizedCourseIds, setFinalizedCourseIds] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [showRecommendedCourses, setShowRecommendedCourses] = useState(false);

  const [transformedMajorRecData, setTransformedMajorRecData] = useState([]);

  // -- ADMIN MODE LOGIC --
  // This state controls whether we are showing the list of students (admin mode)
  // or proceeding to the normal class search.
  const [showStudentList, setShowStudentList] = useState(adjustment === 'admin');
  const [students, setStudents] = useState([]);

  // (New) Keep track of which student we’re editing when in admin mode
  const [selectedStudentId, setSelectedStudentId] = useState(null);

  // If in admin mode, fetch list of students
  useEffect(() => {
    if (adjustment === 'admin') {
      const fetchStudents = async () => {
        try {
          const snapshot = await db.collection('users').get();
          const studentData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setStudents(studentData);
        } catch (error) {
          console.error('Error fetching students:', error);
        }
      };
      fetchStudents();
    }
  }, [adjustment]);

  // Debounce delay in milliseconds
  const DEBOUNCE_DELAY = 1;

  // Transform fetchedMajorRecs
  useEffect(() => {
    if (fetchedMajorRecs) {
      const transformedData = fetchedMajorRecs.reduce((acc, curr) => {
        const { Major, ...categories } = curr;
        acc[Major] = categories;
        return acc;
      }, {});
      setTransformedMajorRecData(transformedData);
    }
  }, [fetchedMajorRecs]);

  // Fetch user data (uses selectedStudentId if admin mode)
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // If we’re in admin mode AND have a selected student, use that ID
        // Otherwise, use the currentUser’s ID
        const userIdToUse =
          adjustment === 'admin' && selectedStudentId
            ? selectedStudentId
            : currentUser?.uid;

        // If there's no user ID to use, there's nothing to fetch
        if (!userIdToUse) {
          setLoading(false);
          return;
        }

        const userDocRef = db.collection('users').doc(userIdToUse);
        const userDoc = await userDocRef.get();

        if (userDoc.exists) {
          const userData = userDoc.data();
          const activeTab = userData.activeTab || '';
          const courseIds = userData.schedules[activeTab] || [];
          const backendSurveyAnswers = userData.surveyAnswers || [];
          const backendUserRecData = userData.recommendationRatings || {};
          setFinalizedCourseIds(courseIds);
          setSurveyAnswers(backendSurveyAnswers);
          setUserRecsData(backendUserRecData);

          const allCourseIds = [];

          for (const yearKey in courseIds) {
            const yearObj = courseIds[yearKey];

            // Iterate over each semester in the year
            for (const semesterKey in yearObj) {
              const semesterIds = yearObj[semesterKey].courses;
              allCourseIds.push(...semesterIds);
            }
          }
          const uniqueCourseIds = [...new Set(allCourseIds)];
          setSelectedClasses(uniqueCourseIds);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
      setLoading(false);
    };

    fetchUserData();
  }, [
    currentUser,
    setSelectedClasses,
    adjustment,
    selectedStudentId, // re-fetch if admin chooses a different student
  ]);

  const uniqueLevels = useMemo(() => {
    if (!fetchedCourseData) return [];
    const levels = new Set();

    Object.values(fetchedCourseData).forEach((item) => {
      if (item.courseYear) {
        const yearValues = item.courseYear.toString().split(',');
        yearValues.forEach((year) => {
          const trimmedYear = year.trim();
          const yearNum = parseInt(trimmedYear);
          if (!isNaN(yearNum) && yearNum > 0 && yearNum <= 12) {
            levels.add(yearNum);
          }
        });
      }
    });

    return Array.from(levels).sort((a, b) => a - b);
  }, [fetchedCourseData]);

  const uniqueMajors = useMemo(() => {
    if (!fetchedCourseData) return [];
    const majors = new Set();
    Object.values(fetchedCourseData).forEach((item) => {
      if (item.Major) {
        item.Major.split(',').forEach((major) => {
          majors.add(major.trim());
        });
      }
    });
    return Array.from(majors).sort();
  }, [fetchedCourseData]);

  const uniqueCredits = useMemo(() => {
    if (!fetchedCourseData) return [];
    const credits = new Set();
    Object.values(fetchedCourseData).forEach((item) => {
      if (item.Credits !== undefined && item.Credits !== null) {
        const creditString = String(item.Credits)
          .replace(/[^\d.]/g, '')
          .trim();
        const creditValue = parseFloat(creditString);
        if (!Number.isNaN(creditValue)) {
          credits.add(creditValue);
        }
      }
    });
    return Array.from(credits).sort((a, b) => a - b);
  }, [fetchedCourseData]);

  // Define suggestions for MultiSelect components
  const levelSuggestions = useMemo(
    () => uniqueLevels.map((level) => `${level}`),
    [uniqueLevels]
  );

  const majorSuggestions = useMemo(() => uniqueMajors, [uniqueMajors]);

  const creditsSuggestions = useMemo(
    () => uniqueCredits.map((credit) => `${credit} Credit(s)`),
    [uniqueCredits]
  );

  // Debounced filter function
  const debouncedFilter = useCallback(
    debounce(() => {
      filterDataAndSetDisplay();
    }, DEBOUNCE_DELAY),
    [
      fetchedCourseData,
      selectedClasses,
      searchQuery,
      levelFilter,
      majorFilter,
      creditsFilter,
      showRecommendedCourses,
      surveyAnswers,
      userRecsData,
      transformedMajorRecData,
    ]
  );

  // Filter data whenever dependencies change, using debounce
  useEffect(() => {
    debouncedFilter();
    return debouncedFilter.cancel;
  }, [
    fetchedCourseData,
    selectedClasses,
    searchQuery,
    levelFilter,
    majorFilter,
    creditsFilter,
    showRecommendedCourses,
    surveyAnswers,
    userRecsData,
    transformedMajorRecData,
    debouncedFilter,
  ]);

  // Filtering logic
  const filterDataAndSetDisplay = () => {
    if (fetchedCourseData) {
      const filtered = Object.values(fetchedCourseData).filter((item) => {
        const majors = item.Major.split(',').map((major) => major.trim());

        // Check if class is already selected
        if (
          selectedClasses.some(
            (selected) =>
              selected.courseInfo_courseName === item.courseInfo_courseName
          )
        ) {
          return false;
        }

        // Level Filter
        if (
          levelFilter.length > 0 &&
          !levelFilter.some(
            (filter) => parseInt(filter) === parseInt(item.courseYear)
          )
        ) {
          return false;
        }

        // Major Filter
        if (
          majorFilter.length > 0 &&
          !majorFilter.some((filter) => majors.includes(filter))
        ) {
          return false;
        }

        // Credits Filter
        if (
          creditsFilter.length > 0 &&
          !creditsFilter.some(
            (filter) =>
              parseFloat(filter.replace(' Credit(s)', '')) ===
              parseFloat(item.Credits)
          )
        ) {
          return false;
        }

        // Search Query
        const nameMatch = item.courseInfo_courseName
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const numberMatch = item.courseInfo_courseNumber
          .toLowerCase()
          .includes(searchQuery.toLowerCase());

        return nameMatch || numberMatch;
      });

      let sortedData;
      if (showRecommendedCourses) {
        const calculateRecScore = require('../CourseRecommendation');
        sortedData = filtered
          .map((course) => {
            const recScore = calculateRecScore(
              transformedMajorRecData[course.Major],
              userRecsData,
              course,
              surveyAnswers
            );
            return { ...course, recScore };
          })
          .sort((a, b) => b.recScore - a.recScore);
      } else {
        sortedData = filtered.sort((a, b) =>
          a.courseInfo_courseNumber.localeCompare(b.courseInfo_courseNumber)
        );
      }

      setFilteredData(sortedData);
      setRecommendedCourses(sortedData);
      setDisplayedData(sortedData.slice(0, 100));
      setCurrentBatch(1);
      setDisplayedCourseCount(sortedData.length);
    }
  };

  // Infinite scroll: Load more data when sentinel is intersecting
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          displayedData.length < filteredData.length
        ) {
          loadMoreData();
        }
      },
      {
        threshold: 0,
        root: sentinelRef.current ? sentinelRef.current.parentElement : null,
        rootMargin: '100px',
      }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [displayedData, filteredData]);

  const loadMoreData = () => {
    const nextBatch = currentBatch + 1;
    const newData = filteredData.slice(0, nextBatch * 100);
    setDisplayedData(newData);
    setCurrentBatch(nextBatch);
  };

  // Handle resetting all filters
  const handleResetFilters = () => {
    setLevelFilter([]);
    setMajorFilter([]);
    setCreditsFilter([]);
    setShowRecommendedCourses(false);
    setSearchQuery('');
  };

  // Toggle recommendations
  const handleShowRecommendations = () => {
    setShowRecommendedCourses((prev) => !prev);
  };

  // Handle adding a class to the cart
  const handleAddClass = (classData) => {
    const isClassAlreadyAdded =
      selectedClasses.some(
        (selected) =>
          selected.courseInfo_courseNumber === classData.courseInfo_courseNumber
      ) ||
      classesInCart.some(
        (cartItem) =>
          cartItem.courseInfo_courseNumber === classData.courseInfo_courseNumber
      );

    if (!isClassAlreadyAdded) {
      setClassesInCart((prevClasses) => [...prevClasses, classData]);
    } else {
      toast.error(
        `Class with course number ${classData.courseInfo_courseNumber} is already in your cart or schedule.`
      );
    }
  };

  // Handle removing a class from the cart
  const handleRemoveClass = (classToRemove) => {
    const updatedClassesInCart = classesInCart.filter(
      (classData) => classData !== classToRemove
    );
    setClassesInCart(updatedClassesInCart);
  };

  // Helper function to capitalize year keys (e.g., 'year1' -> 'Year 1')
  const capitalize = (str) => {
    const spacedStr = str.replace(/(\D+)(\d+)/, '$1 $2');
    return spacedStr.charAt(0).toUpperCase() + spacedStr.slice(1);
  };

  // Helper function to convert semester codes to readable format
  const getReadableSemester = (sem) =>
    sem === 'S1' ? 'Semester 1' : 'Semester 2';

  // Helper function to find the earliest incomplete semester
  const findEarliestIncompleteSemester = (
    updateObj,
    completedSemesters,
    numYears,
    courseNumber
  ) => {
    for (let year = 1; year <= numYears; year++) {
      const yearKey = `year${year}`;
      for (const sem of ['S1', 'S2']) {
        if (!updateObj[yearKey]) {
          updateObj[yearKey] = {};
        }
        if (!updateObj[yearKey][sem]) {
          updateObj[yearKey][sem] = { courses: [] };
        }

        if (completedSemesters[yearKey] && completedSemesters[yearKey][sem]) {
          continue;
        }

        if (!updateObj[yearKey][sem].courses.includes(courseNumber)) {
          return { targetYear: yearKey, targetSemester: sem };
        }
      }
    }
    return null;
  };

  // Helper function to find the latest incomplete semester
  const findLatestIncompleteSemester = (
    updateObj,
    completedSemesters,
    numYears,
    courseNumber
  ) => {
    for (let year = numYears; year >= 1; year--) {
      const yearKey = `year${year}`;
      for (const sem of ['S2', 'S1']) {
        if (!updateObj[yearKey]) {
          updateObj[yearKey] = {};
        }
        if (!updateObj[yearKey][sem]) {
          updateObj[yearKey][sem] = { courses: [] };
        }

        if (completedSemesters[yearKey] && completedSemesters[yearKey][sem]) {
          continue;
        }

        if (!updateObj[yearKey][sem].courses.includes(courseNumber)) {
          return { targetYear: yearKey, targetSemester: sem };
        }
      }
    }
    return null;
  };

  // Helper function to find the semester where the course is already present
  const findCourseSemester = (updateObj, numYears, courseNumber) => {
    for (let year = 1; year <= numYears; year++) {
      const yearKey = `year${year}`;
      for (const sem of ['S1', 'S2']) {
        if (
          updateObj[yearKey] &&
          updateObj[yearKey][sem] &&
          updateObj[yearKey][sem].courses.includes(courseNumber)
        ) {
          return { yearKey, sem };
        }
      }
    }
    return null;
  };

  // Helper function to find the next available semester
  const findNextAvailableSemester = (
    updateObj,
    completedSemesters,
    numYears,
    courseNumber
  ) => {
    for (let year = 1; year <= numYears; year++) {
      const yearKey = `year${year}`;
      for (const sem of ['S1', 'S2']) {
        if (!updateObj[yearKey]) {
          updateObj[yearKey] = {};
        }
        if (!updateObj[yearKey][sem]) {
          updateObj[yearKey][sem] = { courses: [] };
        }

        if (completedSemesters[yearKey] && completedSemesters[yearKey][sem]) {
          continue;
        }

        if (!updateObj[yearKey][sem].courses.includes(courseNumber)) {
          return { targetYear: yearKey, targetSemester: sem };
        }
      }
    }
    return null;
  };

  // Handle adding classes to the student's schedule
  const handleAddCourses = async (addCourseYear, addCourseSemester) => {
    // If admin & we have a selected ID, use it; otherwise use the currentUser’s ID
    const userIdToUse =
      adjustment === 'admin' && selectedStudentId
        ? selectedStudentId
        : currentUser?.uid;

    if (!userIdToUse) {
      toast.error('No user selected. Cannot add courses.', {
        position: 'top-right',
        autoClose: 5000,
      });
      return;
    }

    try {
      const userDocRef = db.collection('users').doc(userIdToUse);
      const userDoc = await userDocRef.get();
      const userData = userDoc.data();
      const activeTab = userData.activeTab;
      const previousFinalizedSchedule = userData.schedules[activeTab] || {};
      const completedSemesters = userData.completedSemesters || {};
      const numYears = previousFinalizedSchedule?.numYears || 4;
      const lastYear = `year${numYears}`;
      const lastSemester = 'S2';

      const updateObj = previousFinalizedSchedule
        ? { ...previousFinalizedSchedule }
        : {};

      for (const selectedClass of classesInCart) {
        const courseNumber = selectedClass.courseInfo_courseNumber;
        let courseSemester = addCourseSemester;
        let courseYear = addCourseYear;

        if (!courseSemester) {
          courseSemester = 'S1';
        }

        let targetYear = `year${courseYear}`;
        let targetSemester = courseSemester;

        if (!updateObj[targetYear]) {
          // If that year doesn’t exist in the user’s schedule, place it in lastYear + lastSemester
          targetYear = lastYear;
          targetSemester = lastSemester;

          if (!updateObj[targetYear]) {
            updateObj[targetYear] = {};
          }
          if (!updateObj[targetYear][targetSemester]) {
            updateObj[targetYear][targetSemester] = { courses: [] };
          }

          toast.error(
            `Year ${courseYear} is not part of your current plan. "${courseNumber}" has been added to the latest semester (${getReadableSemester(
              targetSemester
            )} of ${capitalize(targetYear)}).`,
            { position: 'top-right', autoClose: 5000 }
          );
        }

        if (!updateObj[targetYear][targetSemester]) {
          updateObj[targetYear][targetSemester] = { courses: [] };
        }

        // Scenario 1: If the desired semester is completed, find an earlier incomplete
        if (
          completedSemesters[targetYear] &&
          completedSemesters[targetYear][targetSemester]
        ) {
          const earliestSemester = findEarliestIncompleteSemester(
            updateObj,
            completedSemesters,
            numYears,
            courseNumber
          );
          if (earliestSemester) {
            targetYear = earliestSemester.targetYear;
            targetSemester = earliestSemester.targetSemester;

            toast.info(
              `${getReadableSemester(
                courseSemester
              )} of ${capitalize(
                targetYear
              )} is marked as complete. "${courseNumber}" has been added to ${getReadableSemester(
                targetSemester
              )} of ${capitalize(targetYear)}.`,
              { position: 'top-right', autoClose: 5000 }
            );
          } else {
            toast.error(
              `No available semesters to add "${courseNumber}". Please review your schedule.`,
              { position: 'top-right', autoClose: 5000 }
            );
            continue;
          }
        }
        // Scenario 2: If the course is already in the schedule
        else if (updateObj[targetYear][targetSemester].courses.includes(courseNumber)) {
          const existingSemester = findCourseSemester(
            updateObj,
            numYears,
            courseNumber
          );
          if (existingSemester) {
            const nextAvailableSemester = findNextAvailableSemester(
              updateObj,
              completedSemesters,
              numYears,
              courseNumber
            );
            if (nextAvailableSemester) {
              targetYear = nextAvailableSemester.targetYear;
              targetSemester = nextAvailableSemester.targetSemester;

              toast.info(
                `The course "${courseNumber}" is already in ${getReadableSemester(
                  existingSemester.sem
                )} of ${capitalize(
                  existingSemester.yearKey
                )}. It has been moved to ${getReadableSemester(
                  targetSemester
                )} of ${capitalize(targetYear)}.`,
                { position: 'top-right', autoClose: 5000 }
              );
            } else {
              toast.error(
                `No available semesters to add "${courseNumber}". Please review your schedule.`,
                { position: 'top-right', autoClose: 5000 }
              );
              continue;
            }
          } else {
            toast.error(
              `The course "${courseNumber}" is marked as present in ${getReadableSemester(
                targetSemester
              )} of ${capitalize(
                targetYear
              )}, but its current location couldn't be determined. Please review your schedule.`,
              { position: 'top-right', autoClose: 5000 }
            );
            continue;
          }
        }

        // Double-check the final chosen spot is not completed
        if (completedSemesters[targetYear] && completedSemesters[targetYear][targetSemester]) {
          toast.error(
            `Cannot add "${courseNumber}" to a completed semester (${getReadableSemester(
              targetSemester
            )} of ${capitalize(targetYear)}).`,
            { position: 'top-right', autoClose: 5000 }
          );
          continue;
        }

        // Check credit limit
        const currentCredits = (
          updateObj[targetYear][targetSemester].courses || []
        ).reduce((total, course) => {
          const existingCourse = Object.values(fetchedCourseData).find(
            (c) => c.courseInfo_courseNumber === course
          );
          return total + (existingCourse ? existingCourse.Credits : 0);
        }, 0);

        if (currentCredits + selectedClass.Credits > 50) {
          const semesterName = targetSemester === 'S1' ? 'Fall' : 'Spring';
          const yearNum = parseInt(targetYear.charAt(4));
          const startYear = parseInt(userData?.surveyAnswers?.SchoolStart?.year);
          const specificYear =
            semesterName === 'Fall'
              ? startYear + yearNum - 1
              : startYear + yearNum;

          toast.error(
            `Error adding ${courseNumber}, as it exceeds 50 credits for the ${semesterName} ${specificYear} semester`,
            { position: 'top-right', autoClose: 5000 }
          );
          continue;
        }

        // Finally add to schedule
        if (!updateObj[targetYear][targetSemester].courses.includes(courseNumber)) {
          updateObj[targetYear][targetSemester].courses.push(courseNumber);
          console.log(
            `Course ${courseNumber} added to ${targetYear} ${targetSemester} successfully.`
          );
        } else {
          console.log(
            `Course ${courseNumber} is already present in ${targetYear} ${targetSemester}.`
          );
        }
      }

      await userDocRef.update({ [`schedules.${activeTab}`]: updateObj });

      setClassesInCart([]);
      history('/Plan');
    } catch (error) {
      console.error('Error adding courses to finalizedSchedule:', error);
      toast.error('An error occurred while adding courses. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
      });
    }
  };

  // Display loading spinner while fetching data
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div role="status">
          <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 
              50 100.591C22.3858 100.591 0 78.2051 0 
              50.5908C0 22.9766 22.3858 0.59082 50 
              0.59082C77.6142 0.59082 100 22.9766 100 
              50.5908ZM9.08144 50.5908C9.08144 73.1895 
              27.4013 91.5094 50 91.5094C72.5987 91.5094 
              90.9186 73.1895 90.9186 50.5908C90.9186 
              27.9921 72.5987 9.67226 50 9.67226C27.4013 
              9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 
              35.9116 97.0079 33.5539C95.2932 28.8227 
              92.871 24.3692 89.8167 20.348C85.8452 
              15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 
              4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 
              0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 
              1.69328 37.813 4.19778 38.4501 6.62326C39.0873 
              9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 
              9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 
              10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 
              17.9648 79.3347 21.5619 82.5849 25.841C84.9175 
              28.9121 86.7997 32.2913 88.1811 35.8758C89.083 
              38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  // If in admin mode and showStudentList is still true, show the student list
  if (adjustment === 'admin' && showStudentList) {
    return (
      <div className="p-4">
        <h1 className="text-xl font-bold mb-4">Admin - Student List</h1>
        {students.map((student) => (
          <div
            key={student.id}
            className="cursor-pointer mb-2 p-2 rounded border hover:bg-gray-100"
            onClick={() => {
              // When admin clicks, remember who was clicked
              setSelectedStudentId(student.id);
              // Then show the regular class search UI
              setShowStudentList(false);
            }}
          >
            {student.firstName && student.lastName
              ? `${student.firstName} ${student.lastName}`
              : `UserID: ${student.id}`}
          </div>
        ))}
      </div>
    );
  }

  // Otherwise, show the regular class search interface
  const toggleInfo = () => {
    setShowInfo((prev) => !prev);
  };

  return (
    <div>
      {adjustment !== 'admin' && <NavBar />}

      <div className="max-w-8xl mt-20 grid grid-cols-10 gap-3">
        {/* Filters Sidebar */}
        <div className="col-span-2">
          <div className="p-4 bg-gray-50 shadow-md border rounded-lg">
            <h5 className="text-lg text-center font-bold mb-4">Filters</h5>

            {/* Search Filter */}
            <div className="mb-2">
              <label
                htmlFor="searchQuery"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Search:
              </label>
              <input
                type="text"
                id="searchQuery"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="form-input mt-1 block w-full rounded-md border-gray-300 
                  shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              />
            </div>

            {/* Level Filter */}
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700 mb-1 ">
                Level:
              </label>
              <MultiSelect
                suggestions={levelSuggestions}
                value={levelFilter}
                onChange={(e) => setLevelFilter(e.target.value)}
                placeholder="Select Level(s)"
                required={false}
                unselectable={[]} 
                tagColor="blue"
              />
            </div>

            {/* Major Filter */}
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Major:
              </label>
              <MultiSelect
                suggestions={majorSuggestions}
                value={majorFilter}
                onChange={(e) => setMajorFilter(e.target.value)}
                placeholder="Select Major(s)"
                required={false}
                unselectable={[]} 
                tagColor="green"
              />
            </div>

            {/* Credits Filter */}
            <div className="mb-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Credits:
              </label>
              <MultiSelect
                suggestions={creditsSuggestions}
                value={creditsFilter}
                onChange={(e) => setCreditsFilter(e.target.value)}
                placeholder="Select Credit(s)"
                required={false}
                unselectable={[]} 
                tagColor="purple"
              />
            </div>

            {/* Reset and Show Recommendations Buttons */}
            <button
              className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm 
                py-2 px-4 rounded mb-2"
              onClick={handleResetFilters}
            >
              Reset Filters
            </button>
            <button
              className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 
                px-4 rounded"
              onClick={handleShowRecommendations}
            >
              {showRecommendedCourses
                ? 'Hide Recommendations'
                : 'Show Recommendations'}
            </button>

            {/* Courses Display Count */}
            <div className="flex justify-center mt-3">
              <span
                className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 
                  rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500"
              >
                {displayedCourseCount} Courses Displayed
              </span>
            </div>
          </div>
        </div>

        {/* Courses Display */}
        <div className="col-span-6">
          <div className="p-4 bg-gray-50 shadow-md border rounded-lg flex flex-col h-full">
            {/* Header with Centered Title and Info Icon */}
            <div className="flex items-center justify-center mb-4 relative">
              <h5 className="text-lg font-bold text-center">
                {showRecommendedCourses ? 'Recommended Courses' : 'Courses'}
              </h5>
              {showRecommendedCourses && (
                <button
                  className="absolute right-0 p-1 rounded hover:bg-gray-200"
                  onClick={toggleInfo}
                  aria-label="Show Recommendation Information"
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </button>
              )}
            </div>

            {/* Info Tooltip */}
            {showInfo && (
              <div
                id="alert-additional-content-1"
                className="fixed top-4 right-4 z-50 p-4 mb-4 text-blue-800 
                  border border-blue-300 rounded-lg bg-blue-50 
                  dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800"
                role="alert"
                style={{ width: '25rem' }}
              >
                <div className="flex items-center mb-2">
                  <svg
                    className="flex-shrink-0 w-4 h-4 mr-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5 
                      A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 
                      1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2
                      a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                  </svg>
                  <span className="sr-only">Info</span>
                  <h3 className="text-lg font-medium">
                    Recommendation Information
                  </h3>
                </div>
                <div className="mt-2 mb-4 text-sm">
                  Recommendation scores are determined by two factors:
                  <br />
                  1. The alignment of course content with your interests,
                  skills, and personality traits, as indicated by Holland Codes.
                  <br />
                  2. The compatibility of course characteristics reported by
                  past students with your preferences from the survey.
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="text-blue-800 bg-transparent border border-blue-800
                      hover:!bg-blue-900 hover:!text-white focus:!ring-4 
                      focus:!outline-none focus:!ring-blue-200 font-medium 
                      rounded-lg text-xs px-3 py-1.5 text-center 
                      dark:hover:!bg-blue-900 dark:!border-blue-800 
                      dark:!text-blue-400 dark:hover:!text-white 
                      dark:focus:!ring-blue-800"
                    onClick={toggleInfo}
                    aria-label="Close"
                  >
                    Dismiss
                  </button>
                </div>
              </div>
            )}

            {/* Courses List with Infinite Scroll */}
            <div
              className="flex-1 overflow-y-auto"
              style={{
                minHeight: 'calc(100vh - 13rem)',
                maxHeight: 'calc(100vh - 13rem)',
              }}
            >
              {showRecommendedCourses ? (
                <div>
                  {recommendedCourses.length === 0 ? (
                    <div className="flex justify-center">
                      <span className="bg-red-100 text-red-800 text-md font-medium me-2 px-2.5 py-0.5 rounded">
                        No Results Found
                      </span>
                    </div>
                  ) : (
                    recommendedCourses
                      .slice(0, currentBatch * 100)
                      .map((classData, index) => (
                        <CoursesListing
                          key={index}
                          classData={classData}
                          onAddClass={handleAddClass}
                          inPlan={selectedClasses.includes(
                            classData.courseInfo_courseNumber
                          )}
                          isRec={true}
                        />
                      ))
                  )}
                </div>
              ) : (
                <div>
                  {displayedData.length === 0 ? (
                    <div className="flex justify-center">
                      <span className="bg-red-100 text-red-800 text-md font-medium me-2 px-2.5 py-0.5 rounded">
                        No Results Found
                      </span>
                    </div>
                  ) : (
                    displayedData.map((classData, index) => (
                      <CoursesListing
                        key={index}
                        classData={classData}
                        onAddClass={handleAddClass}
                        inPlan={selectedClasses.includes(
                          classData.courseInfo_courseNumber
                        )}
                        isRec={false}
                      />
                    ))
                  )}
                </div>
              )}
              <div ref={sentinelRef}></div>
            </div>
          </div>
        </div>

        {/* Class Cart - pass in the "adjustment" prop here as well */}
        <div className="col-span-2">
          <ClassCart
            classesInCart={classesInCart}
            onRemoveClass={handleRemoveClass}
            onAddCourses={handleAddCourses}
            yearNumber={yearNumber}
            semester={semesterFromLink}
            surveyAnswers={surveyAnswers}
            finalizedCourseIds={finalizedCourseIds}
            adjustment={adjustment}
          />
        </div>
      </div>
    </div>
  );
};

export default ClassSearch;
