// src/components/Dashboard/Cards/ChartsCard.jsx
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

import DashboardCard from './DashboardCard';

/**
 * Renders the center "total students" text inside each Pie chart.
 */
const renderCenterText = (total) => (
  <text
    x="50%"
    y="50%"
    textAnchor="middle"
    dominantBaseline="middle"
    style={{ fill: '#333' }}
  >
    <tspan
      x="50%"
      dy="-.7em"
      style={{ fontSize: '20px', fontWeight: 'bold' }}
    >
      {total}
    </tspan>
    <tspan
      x="50%"
      dy="1.2em"
      style={{ fontSize: '12px', fontWeight: '500' }}
    >
      Students
    </tspan>
  </text>
);

const ChartsCard = ({ onHide }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [creditsData, setCreditsData] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [totalCreditsStudents, setTotalCreditsStudents] = useState(0);
  const [totalPlanStudents, setTotalPlanStudents] = useState(0);

  // Toggle to switch between real and fake data
  const USE_FAKE_DATA = true; // Set to false to use real data

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (USE_FAKE_DATA) {
          // Fake Data Mode
          const totalStudents = 367;

          // Example fake data for Credits
          setCreditsData([
            { name: 'No Credits', value: 50, color: '#e65100' },
            { name: '1 - 6 Credits', value: 100, color: '#00695c' },
            { name: '7 - 9 Credits', value: 80, color: '#2e7d32' },
            { name: '10 - 12 Credits', value: 90, color: '#003f8a' },
            { name: '13+ Credits', value: 47, color: '#f57c00' },
          ]);
          setTotalCreditsStudents(totalStudents);

          // Example fake data for Plan Creation
          setPlanData([
            { name: 'Students with a plan', value: 200, color: '#003f8a' },
            { name: 'Students without a plan', value: 167, color: '#00695c' },
          ]);
          setTotalPlanStudents(totalStudents);
        } else {
          // Real Data Mode
          // Replace the following with your actual data fetching logic
          // Example:
          // const creditsResponse = await fetch('/api/credits');
          // const creditsJson = await creditsResponse.json();
          // setCreditsData(creditsJson.data);
          // setTotalCreditsStudents(creditsJson.total);

          // const planResponse = await fetch('/api/plans');
          // const planJson = await planResponse.json();
          // setPlanData(planJson.data);
          // setTotalPlanStudents(planJson.total);

          // For demonstration, using existing hardcoded real data
          setCreditsData([
            { name: 'No Credits', value: 1, color: '#e65100' },
            { name: '1 - 6 Credits', value: 0, color: '#00695c' },
            { name: '7 - 9 Credits', value: 0, color: '#2e7d32' },
            { name: '10 - 12 Credits', value: 5, color: '#003f8a' },
            { name: '13+ Credits', value: 0, color: '#f57c00' },
          ]);
          setTotalCreditsStudents(6);

          setPlanData([
            { name: 'Students with a plan', value: 10, color: '#003f8a' },
            { name: 'Students without a plan', value: 4, color: '#00695c' },
          ]);
          setTotalPlanStudents(14);
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching charts data:', error);
        toast.error('Failed to load chart data.');
        setIsLoading(false);
      }
    };

    fetchData();
  }, [USE_FAKE_DATA]);

  // Pie #1: Credits click handler
  const handleCreditsClick = (_, idx) => {
    const clickedSegment = creditsData[idx];
    navigate('/students', {
      state: { filterType: 'credits', filterValue: clickedSegment.name },
    });
  };

  // Pie #2: Plan click handler
  const handlePlanClick = (_, idx) => {
    const clickedSegment = planData[idx];
    navigate('/students', {
      state: { filterType: 'plan', filterValue: clickedSegment.name },
    });
  };

  return (
    <DashboardCard title="Data Insights" onHide={onHide} isLoading={isLoading}>
      {/* Container for both charts */}
      <div className="flex flex-col md:flex-row items-start justify-between gap-4 w-full">
        {/* LEFT CHART: Credits Planned */}
        <div className="w-full md:w-1/2 bg-white rounded-lg border border-gray-400 p-3 flex flex-col">
          <h3 className="text-md font-medium mb-1 text-center">
            Credits Fall 2025
          </h3>
          <p className="text-xs text-gray-500 text-center mb-2">
            Click a slice to view matching students
          </p>
          <div className="w-full h-48">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Tooltip />
                <Pie
                  data={creditsData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={40}
                  outerRadius={70}
                  label={false}
                  onClick={handleCreditsClick}
                  cursor="pointer"
                >
                  {creditsData.map((entry) => (
                    <Cell key={entry.name} fill={entry.color} />
                  ))}
                </Pie>
                {renderCenterText(totalCreditsStudents)} {/* Dynamic total */}
                <Legend
                  layout="horizontal"
                  align="center"
                  verticalAlign="bottom"
                  iconType="circle"
                  iconSize={8}
                  wrapperStyle={{ fontSize: '0.75rem', marginTop: '8px' }}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* RIGHT CHART: Plan Creation */}
        <div className="w-full md:w-1/2 bg-white rounded-lg border border-gray-400 p-3 flex flex-col">
          <h3 className="text-md font-medium mb-1 text-center">
            Plan Creation
          </h3>
          <p className="text-xs text-gray-500 text-center mb-2">
            Click a slice to view matching students
          </p>
          <div className="w-full h-48">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Tooltip />
                <Pie
                  data={planData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={40}
                  outerRadius={70}
                  label={false}
                  onClick={handlePlanClick}
                  cursor="pointer"
                >
                  {planData.map((entry) => (
                    <Cell key={entry.name} fill={entry.color} />
                  ))}
                </Pie>
                {renderCenterText(totalPlanStudents)} {/* Dynamic total */}
                <Legend
                  layout="horizontal"
                  align="center"
                  verticalAlign="bottom"
                  iconType="circle"
                  iconSize={8}
                  wrapperStyle={{ fontSize: '0.75rem', marginTop: '8px' }}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </DashboardCard>
  );
};

export default ChartsCard;
