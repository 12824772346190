import React, { useState, useEffect, useCallback } from 'react';
import StudentListing from './StudentListing/StudentListing';
import CustomSidebar from '../Sidebar/Sidebar';
import { db, auth } from '../../firebase'; // Make sure you import the Firebase authentication module if needed

const StudentsPage = () => {
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [advisors, setAdvisors] = useState([]);

    useEffect(() => {
        const fetchStudentsData = async () => {
            try {
                // Get the current user ID
                const currentUser = auth.currentUser; // Make sure auth is correctly imported and configured
                if (!currentUser) {
                    console.error('No authenticated user found.');
                    return;
                }
                
                // Fetching data from Firestore where the user ID matches
                const snapshot = await db.collection('users')
                    .where('role', '==', 'student')
                    .get();

                const firestoreData = snapshot.docs.map(doc => {
                    const data = doc.data();
                    console.log('Fire data', data);
                    
                    // Extract Majors and Minors safely
                    const majors = data.surveyAnswers && data.surveyAnswers.Majors ? data.surveyAnswers.Majors : 'No Major';
                    const minors = data.surveyAnswers && data.surveyAnswers.Minors ? data.surveyAnswers.Minors : 'No Minor';
                    const planned = data.courseProgress && data.courseProgress.planned ? data.courseProgress.planned : 0;
                    
                    const taken = data.courseProgress && data.courseProgress.taken ? data.courseProgress.taken : 0;
                    const total = data.courseProgress && data.courseProgress.total ? data.courseProgress.total : 0;
                    console.log('Taken, total, planned: ', taken, total, planned);
                    return {
                        id: doc.id,
                        fromFirestore: true,
                        name: `${data.firstName} ${data.lastName}`,
                        email: data.email || 'N/A',
                        firstName: data.firstName,
                        lastName: data.lastName,
                        GPA: data?.surveyAnswers?.GPA,
                        surveyAnswers: data?.surveyAnswers,
                        major: majors,
                        minor: minors,
                        planned: planned,
                        taken: taken,
                        total: total,
                        classesTaken: data.classesTaken || 0,
                        classesRegistered: data.classesRegistered || 0,
                        lastLoginDate: data.lastLoginDate,
                    };
                });


                setStudents(firestoreData);
                setFilteredStudents(firestoreData);
            } catch (error) {
                console.error('Error fetching students data:', error);
            }
        };


        const fetchAdvisorsData = async () => {
            try {
                // Get the current user ID
                const currentUser = auth.currentUser; // Make sure auth is correctly imported and configured
                if (!currentUser) {
                    console.error('No authenticated user found.');
                    return;
                }
                
                // Fetching data from Firestore where the user ID matches
                const snapshot = await db.collection('advisors').get();

                const firestoreAdvisorsSnapshot = snapshot.docs.map(advisor => advisor.data())
                
                const firestoreAdvisorsData = firestoreAdvisorsSnapshot.map(advisor => `${advisor.firstName} ${advisor.lastName}`);

                setAdvisors(firestoreAdvisorsData);

            } catch (error) {
                console.error('Error fetching students data:', error);
            }
        };

        fetchStudentsData();

        fetchAdvisorsData();
    }, []); 

    const handleSearch = useCallback((searchTerm) => {
        const filtered = students.filter(student =>
            student.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredStudents(filtered);
    }, [students]);

    const handleFilter = useCallback((filters) => {
        let filtered = [...students]; // Start from the full students list

        if (filters.gradYear.length) {
            filtered = filtered.filter(student => filters.gradYear.includes(student.gradYear));
        }
        if (filters.program.length) {
            filtered = filtered.filter(student => filters.program.includes(student.major));
        }
        if (filters.performance.length) {
            filtered = filtered.filter(student => {
                const gpa = parseFloat(student.gpa);
                return filters.performance.some(range => {
                    if (range === '4.0') return gpa === 4.0;
                    if (range === '< 2.0') return gpa < 2.0;
                    const [min, max] = range.split(' - ').map(parseFloat);
                    return gpa >= min && gpa <= max;
                });
            });
        }
        if (filters.advisors.length) {
            filtered = filtered.filter(student => {
                if (student.advisor) {
                    return filters.advisors.includes(student.advisor);
                }
                return false;
            });
        }

        setFilteredStudents(filtered);
    }, [students]);

    return (
        <div className="flex">  
            <div className="ml-20 mb-2 px-3 min-w-[95vw] max-w-[95vw]">

                {/* Sidebar */}
                <div className="w-1/8"> {/* Set a smaller width for the sidebar */}
                    <CustomSidebar adjustment={"Student Search"} />
                </div>
            
                {/* Main Content */}
                <div className="flex-1"> {/* Add margin-left to create space between the sidebar and the results */}
                    <StudentListing students={filteredStudents} advisors={advisors} />
                </div>
            </div>
        </div>
      );      
    };
    
export default StudentsPage;
