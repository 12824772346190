// src/components/ProfilePageComponents/CurrentSchedule.js
import React from 'react';

const CurrentSchedule = ({ authUser, schedule, fetchedCourseData }) => {
    const calculateTotalCredits = (schedule) => {
        const allClasses = Object.values(schedule).flat();

        const filteredCourses = fetchedCourseData.filter(course =>
            allClasses.includes(course.courseInfo_courseNumber)
        );

        const totalCredits = filteredCourses.reduce((sum, course) => {
            return sum + parseInt(course.Credits, 10);
        }, 0);

        return totalCredits;
    };

    return (
        <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
            <h2 className="text-gray-700 font-semibold">
                Current Schedule ({authUser?.surveyAnswers?.Year || 'N/A'}) - Total Credits: {calculateTotalCredits(schedule)}
            </h2>
            {Object.keys(schedule).length > 0 ? (
                <div className="flex justify-between">
                    <div className="w-1/2 pr-1" style={{ maxWidth: 'calc(50%)' }}>
                        <h3 className="text-lg text-gray-500 font-semibold mt-2">Fall Semester</h3>
                        <ul>
                            {schedule.Fall.length > 0 ? (
                                schedule.Fall.map((course, index) => (
                                    <li
                                        key={index}
                                        className="bg-blue-200 text-blue-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-blue-700 dark:text-red-400 border border-red-400 mb-2 inline-block"
                                    >
                                        {course}
                                    </li>
                                ))
                            ) : (
                                <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-red-900 dark:text-red-300">
                                    No Planned Courses for Fall Semester
                                </span>
                            )}
                        </ul>
                    </div>

                    <div className="w-1/2 pl-1" style={{ maxWidth: 'calc(50%)' }}>
                        <h3 className="text-lg text-gray-500 font-semibold mt-2">Spring Semester</h3>
                        <ul>
                            {schedule.Spring.length > 0 ? (
                                schedule.Spring.map((course, index) => (
                                    <li
                                        key={index}
                                        className="bg-blue-200 text-blue-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-blue-700 dark:text-red-400 border border-red-400 mb-2 inline-block"
                                    >
                                        {course}
                                    </li>
                                ))
                            ) : (
                                <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-red-900 dark:text-red-300">
                                    No Planned Courses for Spring Semester
                                </span>
                            )}
                        </ul>
                    </div>
                </div>
            ) : (
                <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                    No Planned Courses for Current Year
                </span>
            )}
        </div>
    );
};

export default CurrentSchedule;
