// Staff.js
import React, { useState, useEffect } from 'react';
import CustomSidebar from '../Sidebar/Sidebar';
import UsersList from './UsersList';
import GroupsList from './GroupsList';
import { db } from '../../firebase';

const Staff = () => {
  const [activeTab, setActiveTab] = useState('users'); // Manage active tab state
  const [userCount, setUserCount] = useState(0);
  const [groupCount, setGroupCount] = useState(0);

  useEffect(() => {
    // Fetch user count
    const fetchUserCount = async () => {
      try {
        const snapshot = await db.collection('users').where('role', 'in', ['admin', 'super_admin']).get();
        setUserCount(snapshot.size);
      } catch (error) {
        console.error('Error fetching user count:', error);
      }
    };

    // Fetch group count
    const fetchGroupCount = async () => {
      try {
        const snapshot = await db.collection('groups').get();
        setGroupCount(snapshot.size);
      } catch (error) {
        console.error('Error fetching group count:', error);
      }
    };

    fetchUserCount();
    fetchGroupCount();
  }, []);

  return (
    <div className="flex min-h-screen">
      <CustomSidebar adjustment={"Staff"}/>
      <div className="flex-1 p-4 ml-20 flex flex-col">
        {/* Enhanced Header Section */}
        <div className="text-center mb-6 mt-3">
          <h1 className="text-3xl font-bold mb-2">Staff Management</h1>
          <p className="text-base text-gray-600">
            Welcome to the Staff Management Dashboard. Here you can view and manage staff members, assign roles, and oversee group allocations. Utilize the tabs below to navigate between Users and Groups.
          </p>
        </div>

        {/* Tab Navigation */}
        <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" role="tablist">
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg transition-colors ${
                  activeTab === 'users'
                    ? 'text-blue-700 border-blue-700'
                    : 'text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                }`}
                onClick={() => setActiveTab('users')}
                type="button"
                role="tab"
              >
                Users ({userCount})
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 border-b-2 rounded-t-lg transition-colors ${
                  activeTab === 'groups'
                    ? 'text-green-700 border-green-700'
                    : 'text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                }`}
                onClick={() => setActiveTab('groups')}
                type="button"
                role="tab"
              >
                Groups ({groupCount})
              </button>
            </li>
          </ul>
        </div>

        {/* Tab Content with Minimum Height and Transition */}
        <div className="mt-6">
          <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md min-h-[500px] transition-all duration-300">
            {activeTab === 'users' && (
              <div role="tabpanel">
                <UsersList updateUserCount={setUserCount} /> {/* Display Users List */}
              </div>
            )}
            {activeTab === 'groups' && (
              <div role="tabpanel">
                <GroupsList updateGroupCount={setGroupCount} /> {/* Display Groups List */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staff;
