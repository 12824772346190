// SuggestedEngagementCard.jsx
import React, { useEffect, useState, useCallback } from 'react';
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext';
import DashboardCard from './DashboardCard';
import { toast } from 'react-toastify';

// ------------------- Helper functions -------------------
const getBorderColorClass = (score) => {
  if (score < 1.5) return 'border-t-8 border-red-800';
  if (score < 2.0) return 'border-t-8 border-orange-500';
  if (score < 2.5) return 'border-t-8 border-yellow-500';
  return 'border-t-8 border-green-500';
};

const getInitials = (firstName = '', lastName = '') => {
  const fInitial = firstName.charAt(0).toUpperCase() || '';
  const lInitial = lastName.charAt(0).toUpperCase() || '';
  return `${fInitial}${lInitial}`;
};

const SuggestedEngagementCard = ({ onHide }) => {
  const { currentUser } = useAuth();
  const db = getFirestore();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const fetchSuggestedStudents = useCallback(async () => {
    if (!currentUser?.uid) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    try {
      // 1) Find all studentAdvisors docs that have currentUser in advisorIds
      const studentAdvisorsRef = collection(db, 'studentAdvisors');
      const qRef = query(
        studentAdvisorsRef,
        where('advisorIds', 'array-contains', currentUser.uid)
      );
      const snap = await getDocs(qRef);

      const studentIds = snap.docs.map((docSnap) => docSnap.id);

      if (studentIds.length === 0) {
        setStudents([]);
        setIsLoading(false);
        return;
      }

      // 2) Fetch all user docs in parallel
      const userDocPromises = studentIds.map((id) =>
        getDoc(doc(db, 'users', id))
      );
      const userDocs = await Promise.all(userDocPromises);

      // 3) Fetch all persistence docs in parallel
      const persistDocPromises = studentIds.map((id) =>
        getDoc(doc(db, 'Persistence', id))
      );
      const persistDocs = await Promise.all(persistDocPromises);

      // We'll collect *all* potential students here
      let allStudents = [];

      // 4) Process each student
      for (let i = 0; i < studentIds.length; i++) {
        const studentId = studentIds[i];
        const userDocSnap = userDocs[i];
        const persistDocSnap = persistDocs[i];

        if (!userDocSnap.exists()) continue; // skip if no user doc

        const userData = userDocSnap.data();

        // Combine data from both docs:
        const firstName =
          (persistDocSnap.exists() && persistDocSnap.data().firstName) ||
          userData.firstName ||
          'Student';
        const lastName =
          (persistDocSnap.exists() && persistDocSnap.data().lastName) ||
          userData.lastName ||
          '';
        const score =
          (persistDocSnap.exists() &&
            persistDocSnap.data().overallPersistenceScore) ??
          userData.persistenceScore ??
          0;
        const majorFromUsers =
          (userData.Majors && userData.Majors[0]) ||
          userData.major ||
          '';
        const majorFromPersistence =
          persistDocSnap.exists() && persistDocSnap.data().major
            ? persistDocSnap.data().major
            : '';
        // Decide which major to display
        const displayMajor =
          majorFromPersistence || majorFromUsers || 'Undeclared Major';

        // Build "engagement factors" from the Persistence doc’s applicableEngagements
        let engagementFactors = [];
        if (
          persistDocSnap.exists() &&
          Array.isArray(persistDocSnap.data().applicableEngagements)
        ) {
          persistDocSnap.data().applicableEngagements.forEach((e) => {
            engagementFactors.push(e.name);
          });
        }

        // Example extra logic:
        if (score < 2.5) {
          engagementFactors.push('Low Persistence Score');
        }
        if (!userData.nextAppointment) {
          engagementFactors.push('No upcoming appointment');
        }
        if (userData.withdrewFromCourse) {
          engagementFactors.push(`Withdrew from ${userData.withdrewFromCourse}`);
        }

        // Create combined student object
        allStudents.push({
          id: studentId,
          firstName,
          lastName,
          photoURL:
            userData.photoURL || userData.profilePicture || '/default.jpg',
          major: displayMajor,
          engagementFactors,
          persistenceScore: parseFloat(score) || 0,
        });
      }

      // -----------------------------
      // NEW LOGIC: Ensure at least 3
      // -----------------------------

      // Sort all students by ascending persistenceScore
      // so that if we need to pull from higher scores, we pull from the 'lowest' possible
      allStudents.sort((a, b) => a.persistenceScore - b.persistenceScore);

      // 1) Low-persistence sub-array
      let lowPersistenceStudents = allStudents.filter(
        (s) => s.persistenceScore < 2.5
      );

      // 2) If fewer than 3, fill from the next "lowest" in the remaining array
      if (lowPersistenceStudents.length < 3) {
        // Take students from allStudents who are not already in lowPersistenceStudents
        // (since we sorted, we just fill from the front of `allStudents`)
        const needed = 3 - lowPersistenceStudents.length;
        const fillerStudents = allStudents
          .filter((s) => !lowPersistenceStudents.includes(s))
          .slice(0, needed);

        // Combine them
        lowPersistenceStudents = [...lowPersistenceStudents, ...fillerStudents];
      }

      // 3) Cap at 10 total
      let finalResults = lowPersistenceStudents.slice(0, 10);

      setStudents(finalResults);
    } catch (err) {
      console.error('Error fetching suggested engagement students:', err);
      toast.error('Failed to fetch suggested students.');
    }
    setIsLoading(false);
  }, [currentUser, db]);

  useEffect(() => {
    fetchSuggestedStudents();
  }, [fetchSuggestedStudents]);

  const total = students.length;

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : 0));
  };
  const handleNext = () => {
    setCurrentIndex((prev) => (prev < total - 1 ? prev + 1 : total - 1));
  };

  const handleContactStudent = (student) => {
    navigate(`/Communications?studentId=${student.id}`);
  };

  if (isLoading) {
    return (
      <DashboardCard
        title="Suggested Engagement"
        onHide={onHide}
        isLoading={isLoading}
      >
        {/* No need for internal loading, SkeletonCard handles it */}
      </DashboardCard>
    );
  }

  if (total === 0) {
    return (
      <DashboardCard
        title="Suggested Engagement"
        onHide={onHide}
        isLoading={isLoading}
      >
        <div className="p-4 text-gray-500 text-sm">
          No suggested students to contact.
        </div>
      </DashboardCard>
    );
  }

  const student = students[currentIndex];

  return (
    <DashboardCard
      title={`Suggested Engagement (${currentIndex + 1}/${total})`}
      onHide={onHide}
      isLoading={isLoading}
    >
      {/* Navigation arrows & card layout container */}
      <div className="flex items-center justify-center space-x-3">
        {/* Left arrow */}
        <button
          onClick={handlePrev}
          disabled={currentIndex === 0}
          className="text-gray-500 disabled:opacity-50"
          aria-label="Previous"
        >
          <svg className="w-6 h-6" viewBox="0 0 20 20" fill="currentColor">
            <path d="M12.293 15.293a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L8.414 10l3.879 3.879a1 1 0 010 1.414z" />
          </svg>
        </button>

        {/* Student Card */}
        <div
          className={`relative w-full max-w-[18rem] min-h-[420px] bg-white rounded shadow-md p-4 flex flex-col items-center justify-start ${getBorderColorClass(
            student.persistenceScore
          )}`}
        >
          {/* Photo or initials */}
          <div className="flex flex-col items-center mb-2">
            {student.photoURL && student.photoURL !== '/default.jpg' ? (
              <img
                src={student.photoURL}
                alt={`${student.firstName} ${student.lastName}`}
                className="w-16 h-16 rounded-full object-cover bg-gray-300 mb-2"
              />
            ) : (
              <div className="w-16 h-16 rounded-full bg-gray-300 flex items-center justify-center mb-2">
                <span className="text-white font-bold">
                  {getInitials(student.firstName, student.lastName)}
                </span>
              </div>
            )}
            {/* Student Name (Link to Profile) */}
            <Link
              to={`/Profile/${student.id}`}
              className="text-xl font-bold text-gray-800 text-center hover:underline"
            >
              {student.firstName} {student.lastName}
            </Link>
          </div>

          {/* Major */}
          <p className="text-sm text-gray-600 mb-4 text-center">
            {student.major}
          </p>

          {/* Engagement Factors */}
          <div className="text-sm text-gray-700 mb-4">
            <h4 className="font-semibold mb-1">Engagement Factors</h4>
            {student.engagementFactors.length > 0 ? (
              <ul className="space-y-1">
                {student.engagementFactors.map((factor, idx) => (
                  <li key={idx} className="flex items-center space-x-2">
                    <svg
                      className="w-4 h-4 text-gray-600"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                    >
                      <circle cx="12" cy="12" r="10" />
                    </svg>
                    <span>{factor}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-xs italic text-gray-500">
                No specific factors found
              </p>
            )}
          </div>

          <div className="flex-grow" />

          {/* Contact Button */}
          <div className="mt-4 w-full flex justify-center">
            <button
              type="button"
              onClick={() => handleContactStudent(student)}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none
                         focus:ring-4 focus:ring-blue-300 font-medium
                         rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2
                         dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              CONTACT STUDENT
            </button>
          </div>
        </div>

        {/* Right arrow */}
        <button
          onClick={handleNext}
          disabled={currentIndex === total - 1}
          className="text-gray-500 disabled:opacity-50"
          aria-label="Next"
        >
          <svg
            className="w-6 h-6 rotate-180"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M12.293 15.293a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L8.414 10l3.879 3.879a1 1 0 010 1.414z" />
          </svg>
        </button>
      </div>
    </DashboardCard>
  );
};

export default SuggestedEngagementCard;
