import React, { useState, useRef, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { BsThreeDots } from 'react-icons/bs';
import { FaPlus, FaMagic, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DraggableCourse from './DraggableCourse'; // Ensure this path is correct

const DroppableSemester = ({
  yearNumber,
  semester,
  courses,
  isCompleted,
  onRemove,
  moveCourse,
  onAlternativesClick,
  onEvaluate,
  viewMode,
  prereqClasses,
  getTotalCreditsForSemester,
  handleDropdownToggle,
  dropdownOpen,
  completedSemesters,
  getYearAndSemesterName,
  hasCoursesInSemester,
  shouldShowMarkCompleteButton,
  handleToggleSemesterComplete,
  handleAddMilestone,
  handleRemoveSemesterCourses,
  handleAutofillCourse,
  setShowSidebar,
  buttonRefs,
  activeTab,
  dropdownRefs,
  isDragging,
  isAutofilling,
  setIsAutofilling,
  yearRefs,
}) => {
  const [placeholderIndex, setPlaceholderIndex] = useState(null);
  const [placeholderHeight, setPlaceholderHeight] = useState(0);
  const [draggedCourse, setDraggedCourse] = useState(null);
  const [hasMovedFromOriginal, setHasMovedFromOriginal] = useState(false);
  const semesterRef = useRef(null);
  const courseRefs = useRef([]);

  // Auto-scrolling variables
  const scrollRef = useRef(null);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);

  // Define the drop behavior
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'course',
    canDrop: (item) => !isCompleted,
    hover(item, monitor) {
      setDraggedCourse(item); // Store the dragged course item
      if (!semesterRef.current) return;

      const hoverBoundingRect = semesterRef.current.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) return;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      let newPlaceholderIndex = courses.length;

      for (let i = 0; i < courses.length; i++) {
        const courseRef = courseRefs.current[i];
        if (courseRef && courseRef.current) {
          const courseBoundingRect = courseRef.current.getBoundingClientRect();
          const courseMiddleY =
            courseBoundingRect.top + courseBoundingRect.height / 2 - hoverBoundingRect.top;

          if (hoverClientY < courseMiddleY) {
            newPlaceholderIndex = i;
            break;
          }
        }
      }

      if (placeholderIndex !== newPlaceholderIndex) {
        setPlaceholderIndex(newPlaceholderIndex);
      }

      // Update placeholder height based on dragged item's height
      if (item.height && placeholderHeight !== item.height) {
        setPlaceholderHeight(item.height);
      }

      // Auto-scrolling logic
      const scrollContainer = scrollRef.current;
      if (scrollContainer) {
        const scrollThreshold = 50; // Distance from the top/bottom to start scrolling
        const scrollSpeed = 10; // Adjust scroll speed

        if (hoverClientY < scrollThreshold) {
          scrollContainer.scrollTop -= scrollSpeed;
          setIsAutoScrolling(true);
        } else if (hoverClientY > scrollContainer.clientHeight - scrollThreshold) {
          scrollContainer.scrollTop += scrollSpeed;
          setIsAutoScrolling(true);
        } else {
          setIsAutoScrolling(false);
        }
      }
    },
    drop(item, monitor) {
      if (!monitor.didDrop()) {
        moveCourse(
          item.course,
          item.fromYear,
          item.fromSemester,
          item.index,
          yearNumber,
          semester,
          placeholderIndex !== null ? placeholderIndex : courses.length
        );
        setPlaceholderIndex(null);
        setPlaceholderHeight(0); // Reset the placeholder height
      }
      setDraggedCourse(null); // Reset after drop
    },
    collect: (monitor) => {
      if (!monitor.isOver()) {
        setPlaceholderIndex(null);
        setPlaceholderHeight(0); // Reset the placeholder height
        setIsAutoScrolling(false);
      }
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });

  // Compute drop states
  const isActive = isOver && canDrop;
  const isInvalid = isOver && !canDrop;

  // Assign ref to the semester container
  drop(semesterRef);

  // Function to assign refs to course components
  const assignCourseRef = (index) => {
    if (!courseRefs.current[index]) {
      courseRefs.current[index] = React.createRef();
    }
    return courseRefs.current[index];
  };

  // Calculate the number of buttons to be rendered
  const buttonCount =
    1 +
    (yearNumber <= 4 ? 1 : 0) +
    (hasCoursesInSemester(yearNumber, semester) ? 1 : 0);

  // Determine the alignment based on viewMode and button count
  let alignmentClass = 'justify-end'; // Default alignment

  if (viewMode === 'vertical') {
    if (buttonCount === 3) {
      alignmentClass = 'justify-center';
    } else if (buttonCount === 2) {
      alignmentClass = 'justify-end';
    }
  }

  // Effect to handle additional side effects if needed
  useEffect(() => {
    // Add any side effects based on drop state here
  }, [isOver, canDrop]);

  return (
    <div ref={semesterRef} className="relative semester-container group">
      {/* Semester Content */}
      <div className="relative overflow-visible">
        {/* Overlay for Valid Drop */}
        {isActive && (
          <div className="absolute inset-0 border-[1.5px] border-blue-500 rounded-lg pointer-events-none transition-all duration-200 z-10"></div>
        )}
        {/* Overlay for Invalid Drop */}
        {isInvalid && (
          <div className="absolute inset-0 border-[1.5px] border-red-500 rounded-lg pointer-events-none transition-all duration-200 z-10"></div>
        )}

        <div
          className={`border-solid border-1 ${
            viewMode === 'horizontal' ? 'p-2.5' : ''
          } rounded-lg border-gray-250 ${isCompleted ? 'bg-edvise' : ''}`}
          style={
            viewMode === 'vertical'
              ? {
                  height: '32rem',
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                }
              : {}
          }
        >
          {/* Header Section Inside the Card */}
          <div
            className={`flex-none flex items-center justify-between ${
              viewMode === 'vertical' ? 'mb-1 bg-gray-50 border-b border-gray-300 p-1.5 w-full' : 'mb-2'
            }`}
          >
            <h2
              className={`year-heading ${
                viewMode === 'vertical' ? 'text-sm' : 'text-base'
              } font-medium`}
            >
              {`${getYearAndSemesterName(
                semester === 'Fall' ? yearNumber : yearNumber + 1
              )} ${semester}`}
            </h2>
            <div className="flex items-center space-x-2">
              <span className="bg-purple-100 text-purple-800 text-xs font-medium px-1 py-0.25 rounded border border-purple-400">
                {getTotalCreditsForSemester(courses)} Credits
              </span>
              <div
                className="relative inline-block text-left"
                ref={(el) => (dropdownRefs.current[`${yearNumber}-${semester}`] = el)}
              >
                <button
                  id={`dropdownButton-${yearNumber}-${semester}`}
                  className="p-0 m-0 border-0 shadow-none text-gray-500 hover:text-gray-700"
                  type="button"
                  onClick={() => handleDropdownToggle(yearNumber, semester)}
                >
                  <BsThreeDots className="w-5 h-5 mt-1.5" />
                </button>
                <div
                  id={`dropdown-${yearNumber}-${semester}`}
                  className={`absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 ${
                    dropdownOpen[`${yearNumber}-${semester}`] ? '' : 'hidden'
                  }`}
                  style={{ left: '-9.25rem' }}
                >
                  <ul
                    className="py-2 text-sm text-gray-700"
                    aria-labelledby={`dropdownButton-${yearNumber}-${semester}`}
                  >
                    {/* <li>
                      <Link
                        to={{
                          pathname: `/SemesterDetails/${yearNumber}/${
                            semester === 'Fall' ? 'S1' : 'S2'
                          }`,
                          search: `?plan=${activeTab}`,
                        }}
                        className="block px-4 py-2 hover:bg-gray-100"
                      >
                        Details
                      </Link>
                    </li> */}
                    <li>
                      <button
                        onClick={() => handleAddMilestone(yearNumber, semester)}
                        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                      >
                        Milestones
                      </button>
                    </li>
                    {hasCoursesInSemester(yearNumber, semester) &&
                      shouldShowMarkCompleteButton(yearNumber, semester) && (
                        <li>
                          <button
                            onClick={() =>
                              handleToggleSemesterComplete(yearNumber, semester)
                            }
                            className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                          >
                            {completedSemesters[`year${yearNumber}`]?.[
                              semester === 'Fall' ? 'S1' : 'S2'
                            ]
                              ? 'Mark Incomplete'
                              : 'Mark Complete'}
                          </button>
                        </li>
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Courses Section */}
          <div
  ref={scrollRef}
  className={`${
    viewMode === 'vertical' ? 'flex-1' : ''
  } overflow-y-auto custom-scrollbar relative p-1.5`}
  style={{
    maxHeight: viewMode === 'horizontal' ? '25rem' : undefined,
    overflowX: 'hidden',
    position: 'relative',
    zIndex: 0,
  }}
>
  {courses.length === 0 && placeholderIndex === null ? (
    <span
      className={`bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded ${
        viewMode === 'vertical' ? 'flex items-center justify-center' : ''
      }`}
    >
      No Courses Planned
    </span>
  ) : (
    <>
      {/* Render courses before the placeholder */}
      {courses
        .filter((c) => {
          // If this course is the one currently being dragged in this same semester, skip it.
          return !(
            draggedCourse &&
            draggedCourse.fromYear === yearNumber &&
            draggedCourse.fromSemester === semester &&
            draggedCourse.course.courseInfo_courseNumber === c.courseInfo_courseNumber
          );
        })
        .slice(0, placeholderIndex !== null ? placeholderIndex : courses.length)
        .map((course, index) => (
          <DraggableCourse
            key={course.courseInfo_courseNumber}
            id={`course-${yearNumber}-${semester}-${index}`}
            course={course}
            index={index}
            setShowSidebar={setShowSidebar}
            yearNumber={yearNumber}
            semester={semester}
            onRemove={(classToRemove) => onRemove(classToRemove, yearNumber, semester)}
            moveCourse={moveCourse}
            onAlternativesClick={onAlternativesClick}
            isCompleted={isCompleted}
            onEvaluate={onEvaluate}
            viewMode={viewMode}
            prereqClasses={prereqClasses}
            ref={assignCourseRef(index)}
            // You can remove isDragging from here if it's unused
            style={
              viewMode === 'vertical'
                ? {
                    overflow: 'visible',
                    margin: '0 auto',
                    maxWidth: 'calc(100% - 10px)',
                    position: 'relative',
                    zIndex: 1,
                  }
                : {}
            }
          />
        ))}

      {/* Placeholder */}
      {placeholderIndex !== null && (
        <div
          className="course-placeholder"
          style={{
            height: `${placeholderHeight}px`,
            backgroundColor: '#e0e0e0',
            margin: '5px 0',
            borderRadius: '5px',
          }}
        ></div>
      )}

      {/* Render courses after the placeholder */}
      {courses
        .filter((c) => {
          // Same filtering logic for the courses after the placeholder
          return !(
            draggedCourse &&
            draggedCourse.fromYear === yearNumber &&
            draggedCourse.fromSemester === semester &&
            draggedCourse.course.courseInfo_courseNumber === c.courseInfo_courseNumber
          );
        })
        .slice(placeholderIndex !== null ? placeholderIndex : courses.length)
        .map((course, index) => {
          const adjustedIndex = index + (placeholderIndex !== null ? placeholderIndex : 0);
          return (
            <DraggableCourse
              key={course.courseInfo_courseNumber}
              id={`course-${yearNumber}-${semester}-${adjustedIndex}`}
              course={course}
              index={adjustedIndex}
              yearNumber={yearNumber}
              setShowSidebar={setShowSidebar}
              semester={semester}
              onRemove={(classToRemove) => onRemove(classToRemove, yearNumber, semester)}
              moveCourse={moveCourse}
              onAlternativesClick={onAlternativesClick}
              isCompleted={isCompleted}
              onEvaluate={onEvaluate}
              viewMode={viewMode}
              prereqClasses={prereqClasses}
              ref={assignCourseRef(adjustedIndex)}
              // You can remove isDragging if unused
              style={
                viewMode === 'vertical'
                  ? {
                      overflow: 'visible',
                      margin: '0 auto',
                      maxWidth: 'calc(100% - 10px)',
                      position: 'relative',
                      zIndex: 1,
                    }
                  : {}
              }
            />
          );
        })}
    </>
  )}
</div>


          {/* Footer Section - Conditionally Rendered */}
          {!isCompleted && (
            <div
              className={`${
                viewMode === 'vertical'
                  ? `flex-none hidden group-hover:flex transition-opacity duration-300 ${alignmentClass}`
                  : 'flex justify-end'
              } mt-2 bg-white py-2`}
            >
              <div className="flex space-x-2 add-autofill-container">
                {/* Add Button */}
                <button
                  ref={(el) => buttonRefs.current.push(el)}
                  className="text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center add-btn"
                >
                  <Link
                    to={{
                      pathname: '/Search',
                      search: `?yearNumber=${yearNumber}&semester=${semester}`,
                    }}
                  >
                    <FaPlus className="inline mb-0.5" /> Add
                  </Link>
                </button>

                {/* Autofill Button */}
                {yearNumber <= 4 && (
                  <>
                    <div className="border-gray-300 border-l h-5 inline-block mt-[5px]"></div>
                    <button
                      ref={(el) => buttonRefs.current.push(el)}
                      onClick={() => handleAutofillCourse(yearNumber, semester)}
                      className={`text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center autofill-btn ${
                        !hasCoursesInSemester(yearNumber, semester) ? 'mr-2' : ''
                      }`}
                    >
                      <FaMagic className="inline mr-1 mb-0.5" /> Autofill
                    </button>
                  </>
                )}
              </div>

              {/* Clear Button */}
              {hasCoursesInSemester(yearNumber, semester) && (
                <>
                  <div className="border-gray-300 ml-2 border-l h-5 inline-block mt-[5px] mr-[2px]"></div>
                  <button
                    ref={(el) => buttonRefs.current.push(el)}
                    onClick={() =>
                      handleRemoveSemesterCourses(
                        yearNumber,
                        semester === 'Fall' ? 'S1' : 'S2'
                      )
                    }
                    className="mr-1 text-blue-700 hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs text-center autofill-btn"
                  >
                    <FaTrash className="inline mr-0.5 mb-0.5 ml-0.5" /> Clear
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DroppableSemester;
