// src/components/Dashboard/Cards/ScheduledReportsCard.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { Button } from 'flowbite-react';
import DashboardCard from './DashboardCard';
import { toast } from 'react-toastify';

const ScheduledReportsCard = ({ onHide }) => {
  const [scheduledReports, setScheduledReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch reports immediately without delay
    try {
      // Replace with actual data fetching logic
      const data = [
        {
          id: 'report1',
          name: 'Student Planning Progress',
          latestReport: {
            id: 'latest1',
            createdAt: new Date(),
            summaryStatistics: { Credits: { count: 211 } },
          },
          previousReport: {
            id: 'prev1',
            createdAt: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
            summaryStatistics: { Credits: { count: 180 } },
          },
        },
        {
          id: 'report2',
          name: 'Weekly Grades Report',
          latestReport: {
            id: 'latest2',
            createdAt: new Date(),
            summaryStatistics: { GPA: { sum: 3.3 } },
          },
          previousReport: {
            id: 'prev2',
            createdAt: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            summaryStatistics: { GPA: { sum: 3.2 } },
          },
        },
        {
          id: 'report3',
          name: 'Monthly Attendance Report',
          latestReport: {
            id: 'latest3',
            createdAt: new Date(),
            summaryStatistics: { Attendance: { percentage: 95 } },
          },
          previousReport: {
            id: 'prev3',
            createdAt: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
            summaryStatistics: { Attendance: { percentage: 92 } },
          },
        },
        {
          id: 'report4',
          name: 'Semester Enrollment Trends',
          latestReport: {
            id: 'latest4',
            createdAt: new Date(),
            summaryStatistics: { Enrollments: { current: 330, previous: 480 } },
          },
          previousReport: {
            id: 'prev4',
            createdAt: new Date(Date.now() - 180 * 24 * 60 * 60 * 1000),
            summaryStatistics: { Enrollments: { current: 352, previous: 460 } },
          },
        },
      ];
      setScheduledReports(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching scheduled reports:', error);
      setIsLoading(false);
      toast.error('Failed to load scheduled reports.');
    }
  }, []);

  const handleViewReport = (reportId) => {
    navigate('/reports', { state: { reportId } });
  };

  return (
    <DashboardCard title="Scheduled Reports" onHide={onHide} isLoading={isLoading}>
      {!isLoading && (
        scheduledReports.length > 0 ? (
          <div className="flex space-x-4 overflow-x-auto">
            {scheduledReports.map((report) => {
              const lastGeneratedAt = report.latestReport?.createdAt;
              const timeSinceLastReport = lastGeneratedAt
                ? formatDistanceToNow(lastGeneratedAt, { addSuffix: true })
                : 'No reports yet';

              const keys = report.latestReport?.summaryStatistics
                ? Object.keys(report.latestReport.summaryStatistics)
                : [];
              const primaryKey = keys[0] || 'Value';
              const latestVal = report.latestReport?.summaryStatistics[primaryKey];
              const prevVal = report.previousReport?.summaryStatistics[primaryKey];

              let latestValue = 0;
              let previousValue = 0;

              // Handle different summary statistics structures
              if (primaryKey === 'Enrollments') {
                latestValue = latestVal
                  ? latestVal.current || 0
                  : 0;
                previousValue = prevVal
                  ? prevVal.current || 0
                  : 0;
              } else if (primaryKey === 'Attendance') {
                latestValue = latestVal
                  ? latestVal.percentage || 0
                  : 0;
                previousValue = prevVal
                  ? prevVal.percentage || 0
                  : 0;
              } else {
                latestValue = latestVal
                  ? latestVal.sum || latestVal.count || 0
                  : 0;
                previousValue = prevVal // ❌ Fixed: Use prevVal instead of latestVal
                  ? prevVal.sum || prevVal.count || 0
                  : 0;
              }

              let percentageChange = null;
              if (previousValue !== 0) {
                percentageChange =
                  ((latestValue - previousValue) / previousValue) * 100;
              }
              const formattedChange =
                percentageChange !== null ? percentageChange.toFixed(2) : null;

              return (
                <div
                  key={report.id}
                  onClick={() => handleViewReport(report.id)}
                  className="cursor-pointer bg-white dark:bg-gray-800 border border-gray-300
                             dark:border-gray-700 rounded-lg shadow-md p-2 w-1/4 flex flex-col justify-between relative transition-all duration-300"
                >
                  <h5 className="text-xs font-medium text-gray-600 dark:text-gray-400 truncate mb-1">
                    {report.name}
                  </h5>
                  <div className="flex items-center text-sm text-gray-900 dark:text-white">
                    <p className="text-base font-bold">
                      {primaryKey === 'Attendance'
                        ? `${latestValue}%`
                        : primaryKey === 'Enrollments'
                        ? `${latestValue}`
                        : latestValue}
                    </p>
                    <span className="ml-1 text-gray-500">{primaryKey}</span>
                    {formattedChange !== null && (
                      <span
                        className={`ml-2 text-xs font-medium ${
                          percentageChange >= 0
                            ? 'text-green-500'
                            : 'text-red-500'
                        }`}
                      >
                        {percentageChange >= 0 ? '↑' : '↓'}{' '}
                        {Math.abs(formattedChange)}%
                      </span>
                    )}
                  </div>
                  {lastGeneratedAt && (
                    <span className="text-gray-500 text-xs mt-1">
                      {timeSinceLastReport}
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-center py-5">
            <p className="text-gray-600 dark:text-gray-400 mb-3">
              You have no scheduled reports.
            </p>
            <Button
              className="text-blue-700 hover:text-white border border-blue-700
                         hover:bg-blue-800 focus:ring-4 focus:outline-none
                         focus:ring-blue-300 font-medium rounded-lg text-sm
                         px-5 py-2.5 text-center dark:border-blue-500
                         dark:text-blue-500 dark:hover:text-white
                         dark:hover:bg-blue-500 dark:focus:ring-blue-800"
              onClick={() => navigate('/reports')}
            >
              Create a Recurring Report
            </Button>
          </div>
        )
      )}
    </DashboardCard>
  );
};

export default ScheduledReportsCard;
