// ResourcesCard.js

import React from 'react';

const ResourcesCard = ({ resource, handleShowDetails }) => {
  return (
    <div
      className="bg-white border border-gray-200 rounded-lg shadow-sm 
                 dark:bg-gray-800 dark:border-gray-700 flex flex-col h-full"
    >
      <img
        className="rounded-t-lg h-40 w-full object-cover"
        src={resource.image || 'https://via.placeholder.com/300x200'}
        alt={resource.name}
      />
      <div className="p-4 flex flex-col flex-grow">
        <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
          {resource.name}
        </h5>
        {/* Removed the inline description and the action buttons.
            Instead, we only show a "Details" button. */}
        <div className="mt-auto flex justify-center">
          <button
            type="button"
            onClick={() => handleShowDetails(resource)}
            className="px-3 py-2 text-sm font-medium text-gray-900 bg-white 
                       border border-gray-200 rounded-lg hover:!bg-gray-100 
                       hover:!text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 
                       focus:!text-blue-700 dark:bg-gray-800 dark:border-gray-700 
                       dark:text-white dark:hover:!text-white dark:hover:!bg-gray-700 
                       dark:focus:ring-blue-500 dark:focus:!text-white"
          >
            Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResourcesCard;
