// src/components/Dashboard/Cards/EngagementOpportunitiesCard.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { toast } from 'react-toastify';
import DashboardCard from './DashboardCard';

const EngagementOpportunitiesCard = ({ onHide }) => {
  const [opportunityReports, setOpportunityReports] = useState([]);
  const [expandedCards, setExpandedCards] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const db = getFirestore();
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await getDocs(collection(db, 'engagementResources'));
        const opportunities = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOpportunityReports(opportunities);
      } catch (error) {
        console.error('Error fetching engagement opportunities:', error);
        toast.error('Failed to fetch engagement opportunities.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [db]);

  const toggleExpand = (id) => {
    setExpandedCards((prev) =>
      prev.includes(id) ? prev.filter((cardId) => cardId !== id) : [...prev, id]
    );
  };

  return (
    <DashboardCard title="Engagement Opportunities" onHide={onHide} isLoading={isLoading}>
      {!isLoading && (
        <div className="overflow-x-auto whitespace-nowrap">
          <div className="flex space-x-4">
            {opportunityReports.map((op) => {
              const count = op.matchingUsersCount || 0;
              const criticallyLow = parseInt(op.criticallyLow, 10);
              const criticallyHigh = parseInt(op.criticallyHigh, 10);
              const previousCount = op.previousCount || 0;
              const countChange = count - previousCount;
              const isExpanded = expandedCards.includes(op.id);

              return (
                <div
                  key={op.id}
                  onClick={() =>
                    navigate("/StudentPersistence", { state: { filterOpportunity: op.name } })
                  }
                  className="cursor-pointer bg-white mb-1 dark:bg-gray-800 border border-gray-300
                             dark:border-gray-700 rounded-lg shadow-md p-2 w-1/4
                             flex flex-col justify-between relative transition-all duration-300"
                >
                  <h5 className="text-xs font-medium text-gray-600 dark:text-gray-400 truncate mb-2">
                    {op.name}
                  </h5>

                  <div className="flex items-center text-sm text-gray-900 dark:text-white mt-auto">
                    <p className="text-sm font-bold">{count}</p>
                    <span className="ml-1 text-gray-500">students</span>
                    {countChange !== 0 && (
                      <span
                        className={`ml-2 text-xs font-medium ${
                          countChange > 0 ? 'text-green-500' : 'text-red-500'
                        }`}
                      >
                        {countChange > 0 ? '↑' : '↓'} {Math.abs(countChange)}
                      </span>
                    )}
                  </div>

                  {(count < criticallyLow || count > criticallyHigh) && (
                    <span
                      className="absolute bottom-2 right-2 text-red-500 text-sm"
                      title={
                        count < criticallyLow
                          ? 'Below Critical Threshold'
                          : 'Above Critical Threshold'
                      }
                    >
                      ⚠️
                    </span>
                  )}

                  <div
                    style={{
                      maxHeight: isExpanded ? '500px' : '0',
                      overflow: 'hidden',
                      transition: 'max-height 0.3s ease',
                    }}
                  >
                    {isExpanded && (
                      <ul className="mt-2 text-sm text-gray-700 dark:text-gray-300">
                        {op.matchingUsersList && op.matchingUsersList.length > 0 ? (
                          op.matchingUsersList.map((student, index) => (
                            <li key={index}>
                              {student.firstName} {student.lastName}
                            </li>
                          ))
                        ) : (
                          <li>No students found.</li>
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </DashboardCard>
  );
};

export default EngagementOpportunitiesCard;
