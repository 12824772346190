// src/components/Dashboard/Dashboard.jsx
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import CustomSidebar from '../Sidebar/Sidebar';
import { toast } from 'react-toastify';

// Firestore
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

// Cards
import EngagementOpportunitiesCard from './Cards/EngagementOpportunitiesCard';
import ChartsCard from './Cards/ChartsCard';
import ScheduledReportsCard from './Cards/ScheduledReportsCard';
import QuickActionsCard from './Cards/QuickActionsCard';
import NoContactCard from './Cards/NoContactCard'; 
import SuggestedEngagementCard from './Cards/SuggestedEngagementCard'; 
import PersistencePredictionCard from './Cards/PersistencePredictionCard'; 

const Dashboard = () => {
  const { currentUser } = useAuth();
  const db = getFirestore();

  const [firstName, setFirstName] = useState('');
  const [lastUpdated, setLastUpdated] = useState(null);

  // Track hidden state for each card
  const [hiddenCards, setHiddenCards] = useState({
    engagementOpportunities: false,
    charts: false,
    scheduledReports: false,
    quickActions: false,
    noContactCard: false,
    suggestedEngagementCard: false,
    persistencePrediction: false, // Make sure you have a key for this if you want to hide it
  });

  // Load preferences from Firestore on mount
  useEffect(() => {
    const fetchPreferences = async () => {
      if (!currentUser?.uid) return;
      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const data = userDocSnap.data();
          if (data.dashboardPreferences?.hiddenCards) {
            setHiddenCards((prev) => ({
              ...prev,
              ...data.dashboardPreferences.hiddenCards
            }));
          }
        }
      } catch (error) {
        console.error('Error fetching dashboard preferences:', error);
      }
    };
    fetchPreferences();
  }, [currentUser, db]);

  // Hide a single card
  const hideCard = async (cardKey) => {
    if (!currentUser?.uid) return;
    try {
      setHiddenCards((prev) => {
        const newHidden = { ...prev, [cardKey]: true };
        // Store in Firestore
        const userDocRef = doc(db, 'users', currentUser.uid);
        setDoc(
          userDocRef,
          { dashboardPreferences: { hiddenCards: newHidden } },
          { merge: true }
        );
        return newHidden;
      });
    } catch (error) {
      console.error('Error hiding card in Firestore:', error);
      toast.error('Failed to hide card.');
    }
  };

  // Reset to default => unhide all or any default arrangement
  const resetToDefault = async () => {
    if (!currentUser?.uid) return;
    const defaultHidden = {
      engagementOpportunities: false,
      charts: false,
      scheduledReports: false,
      quickActions: false,
      noContactCard: false,
      suggestedEngagementCard: false,
      persistencePrediction: false,
    };
    try {
      setHiddenCards(defaultHidden);
      const userDocRef = doc(db, 'users', currentUser.uid);
      await setDoc(
        userDocRef,
        { dashboardPreferences: { hiddenCards: defaultHidden } },
        { merge: true }
      );
      toast.success('Dashboard reset to default!');
    } catch (error) {
      console.error('Error resetting to default layout:', error);
      toast.error('Failed to reset dashboard.');
    }
  };

  // Greeting
  useEffect(() => {
    if (currentUser && currentUser.firstName) {
      setFirstName(currentUser.firstName);
    } else {
      setFirstName('Advisor');
    }
    setLastUpdated(new Date());
  }, [currentUser]);

  const handleCaseloadClick = () => {
    toast.info('Open a modal or navigate to change caseload...');
  };

  return (
    <div className="bg-gray-50 flex" style={{ display: 'flex', width: '100vw', flexGrow: '1' }}>
      <CustomSidebar adjustment="Dashboard" />

      {/* Main Content */}
      <div className="ml-[6vw] min-w-[92vw] max-w-[92vw] pt-8 mb-2">
        {/* Top row */}
        <div className="flex items-center justify-between gap-2 mb-2">
          <div className="text-xl font-semibold">
            Welcome, {firstName}!
          </div>
          <div className="text-md">
            {lastUpdated
              ? `Last Updated: ${lastUpdated.toLocaleString()}`
              : 'No updates yet'}
          </div>
          <div className="text-md">
            Viewing data for your{' '}
            <span
              className="underline cursor-pointer text-blue-600 hover:text-blue-800"
              onClick={handleCaseloadClick}
            >
              caseload
            </span>
          </div>
        </div>

        {/* 3-column grid layout */}
        <div className="grid grid-cols-3 gap-4" style={{ minHeight: 'calc(100vh - 120px)' }}>
          {/* Left Column (col-span-2) */}
          <div className="col-span-2 space-y-2">
            {!hiddenCards.engagementOpportunities && (
              <EngagementOpportunitiesCard onHide={() => hideCard('engagementOpportunities')} />
            )}
            {!hiddenCards.persistencePrediction && (
              <PersistencePredictionCard onHide={() => hideCard('persistencePrediction')} />
            )}
            {!hiddenCards.charts && (
              <ChartsCard onHide={() => hideCard('charts')} />
            )}
            {!hiddenCards.scheduledReports && (
              <ScheduledReportsCard onHide={() => hideCard('scheduledReports')} />
            )}
          </div>

          {/* Right Column (col-span-1), "thin but tall" */}
          <div className="col-span-1 flex flex-col space-y-2">
            {/* Quick Actions at the top */}
            {!hiddenCards.quickActions && (
              <QuickActionsCard onHide={() => hideCard('quickActions')} />
            )}  
            {!hiddenCards.suggestedEngagementCard && (
              <SuggestedEngagementCard onHide={() => hideCard('suggestedEngagementCard')} />
            )}
            {!hiddenCards.noContactCard && (
              <NoContactCard onHide={() => hideCard('noContactCard')} />
            )}
          </div>
        </div>

        {/* Reset button (small) */}
        <button
          onClick={resetToDefault}
          className="bg-blue-500 hover:bg-blue-600 text-white rounded px-3 py-1 text-sm mt-4"
        >
          Reset to Default
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
