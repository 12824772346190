// heading.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faMapMarkerAlt, faBook, faTag } from '@fortawesome/free-solid-svg-icons';
import TagList from './TagList';

const Heading = ({ authUser }) => {

    const [clickCount, setClickCount] = useState(0);

    const photoURL = authUser.photoUrl || '/default.jpg';
    const navigate = useNavigate();

    const userFullName = () => {
        if (authUser && authUser.firstName && authUser.lastName) {
            return `${authUser.firstName} ${authUser.lastName}`;
        }
        return 'User';
    };

    const handleNameClick = () => {
        setClickCount((prevCount) => {
            const newCount = prevCount + 1;
            if (newCount === 4) {
                navigate('/roles');
            }
            return newCount;
        });
    };

    return (
        <div className="flex items-center mb-1">
            <img
                className="w-[160px] h-[160px] mb-4 mr-5 rounded-full"
                src={photoURL || '/default.jpg'}
                alt="Profile Picture"
            />
            <div className="flex-grow max-w-[calc(100%-100px)]">
                <h1 className="text-2xl font-bold mb-1" onClick={handleNameClick}>
                    {userFullName()}
                </h1>
                {authUser?.email && <p className="text-sm mb-1">{authUser.email}</p>}
                {(authUser?.surveyAnswers?.Year || 'N/A') && (authUser?.surveyAnswers?.Faculties?.length > 0 || 'N/A') && (
                    <p className="text-sm mb-1">
                        {authUser?.surveyAnswers?.Year || 'N/A'} - {authUser?.surveyAnswers?.Faculties?.join(', ') || 'N/A'}
                    </p>
                )}
                {authUser?.surveyAnswers?.Majors?.length > 0 && (
                    <p className="text-sm">
                        <FontAwesomeIcon icon={faBook} className="mr-1" />
                        {authUser?.surveyAnswers?.Majors?.length === 1 ? 'Major in' : 'Majors in'} {authUser?.surveyAnswers?.Majors?.join(', ')}
                    </p>
                )}
                {authUser?.surveyAnswers?.Minors?.length > 0 && (
                    <p className="text-sm mb-1">
                        <FontAwesomeIcon icon={faBook} className="mr-1" /> 
                        {authUser.surveyAnswers.Minors.length === 1 ? 'Minor in' : 'Minors in'} {authUser.surveyAnswers.Minors.join(', ')}
                    </p>
                )}
                {authUser?.surveyAnswers?.Campus && (
                    <p className="text-sm mb-1">
                        <FontAwesomeIcon icon={faMapMarkerAlt} /> {authUser.surveyAnswers.Campus}
                    </p>
                )}
                {(() => {
                    const startMonth = authUser?.surveyAnswers?.SchoolStart?.month;
                    const startYear = authUser?.surveyAnswers?.SchoolStart?.year;
                    const endMonth = authUser?.surveyAnswers?.SchoolEnd?.month;
                    const endYear = authUser?.surveyAnswers?.SchoolEnd?.year;

                    const startDate = startMonth && startYear ? `${startMonth} ${startYear}` : 'N/A';
                    const endDate = endMonth && endYear ? `${endMonth} ${endYear}` : 'N/A';

                    if (startDate !== 'N/A' || endDate !== 'N/A') {
                        return (
                            <p className="text-sm mb-1">
                                <FontAwesomeIcon icon={faGraduationCap} /> {startDate} - {endDate}
                            </p>
                        );
                    }

                    return null;
                })()}
                {(authUser?.surveyAnswers?.Pronouns || (Array.isArray(authUser?.surveyAnswers?.Tags) && authUser.surveyAnswers.Tags.length > 0)) && (
                    <div className="flex items-center text-sm">
                        <FontAwesomeIcon icon={faTag} className="mr-1 mb-1" />
                        {authUser?.surveyAnswers?.Pronouns && (
                            <span className="mr-2 mb-1">Pronouns: {authUser.surveyAnswers.Pronouns}</span>
                        )}
                        <TagList tags={authUser?.surveyAnswers?.Tags} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Heading;